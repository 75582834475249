<template>
    <div class="service_details_container" style="overflow-x: hidden;">
        <div class="form-preloader" v-if="preparingForm">
            <div class="preloader-spinner">
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="preloader-text">Preparing form. Please wait...</div>
        </div>
        <img class="title_image" src="../../assets/img_shipping.png" @dblclick="showData" />
        <h4>START SHIPPING - COURIER BOOKING</h4>
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
            <section>
                <div class="h2_wiith_icons">
                    <div class="help_links">
                        <router-link to="/pages/help"><span>Help</span></router-link>
                        |
                        <router-link to="/pages/faq"><span>FAQ</span></router-link>
                    </div>
                    <div class="adj_cover">
                        <h2>SELECT SENDER AND RECEIVER</h2>
                        <div class="adj_in">
                            <i class="material-icons">info</i>

                            <div class="info_box">
                                <header>Quote</header>
                                <section>
                                    Select carriers based on type of shipment
                                    you are sending, pick up, delivery address
                                    and weight.
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider name="requester name" rules="required|max:100" v-slot="{ errors }"
                                    slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Requester Name
                                            <span>*</span>
                                        </label>
                                        <input type="text" placeholder="Name Character Limit 100"
                                            v-model="draft.requester_name" @focus="show_requester_suggestion = true"
                                            @blur="hideRequesterSuggestions" :maxlength="max4"
                                            autocomplete="new-password" :class="errors[0]
                                                ? 'dynamic_data_trigger warning-border'
                                                : 'dynamic_data_trigger'
                                                " :disabled="isDisabled" />
                                        <div class="dynamic_data_wrapper" v-if="show_requester_suggestion">
                                            <span v-for="(address, i) in filteredRequesterAddress"
                                                :key="'requester_address_' + i" @click="setReqesterName(address);">
                                                {{ address.name }}
                                            </span>
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider name="unit name" rules="required" v-slot="{ errors }" slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Units / Branch
                                            <span>*</span>
                                        </label>
                                        <select v-model="draft.branch_id" :class="errors[0]
                                            ? 'warning-border'
                                            : ''
                                            " :disabled="isDisabled">
                                            <option value="">Select a branch</option>
                                            <option v-for="(branch, i) in branches" :key="'branches' + i"
                                                :value="branch.id">{{ branch.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Request Date
                                        <span>*</span>
                                    </label>
                                    <flat-pickr class="date_for_pos_fixed" v-model="draft.request_date"
                                        :config="configDate" placeholder="Date" name="birthdate"
                                        :disabled="enable_edit_var == false"></flat-pickr>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="form_section1" v-if="edit_mode == true">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <a class="new_yellow_edit_bt" @click="enableEditMode">EDIT BOOKING DETAILS</a>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>

                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Pickup Address
                                        <span>*</span>
                                    </label>
                                    <label>
                                        <span>
                                            <a @click="changePickup = true" v-if="enable_edit_var != false"
                                                style="background-color: #004a7c;padding: 0.7rem;padding: 6px 7px;color:#fff;">
                                                PICKUP ADDRESS
                                            </a>
                                        </span>
                                        <span style="padding-left:5px;">
                                            <a @click="
                                                viewCompanyAddressBook('pickup')
                                                " v-if="enable_edit_var != false"
                                                style="background-color: #004a7c;padding: 0.7rem;padding: 6px 7px;color:#fff;">
                                                COMPANY ADDRESS BOOK
                                            </a>
                                        </span>
                                        <span style="padding-left:5px;">
                                            <a @click="
                                                viewPersonalAddressBook(
                                                    'pickup'
                                                )
                                                " v-if="enable_edit_var != false"
                                                style="background-color: #004a7c;padding: 0.7rem;padding: 6px 7px;color:#fff;">
                                                PERSONAL ADDRESS BOOK
                                            </a>
                                        </span>
                                        <span style="padding-left:5px;cursor:pointer;"
                                            v-if="rateCalculator(pickup_rate)">
                                            <span @click="viewAddressHistory(pickup_rate)"
                                                style="color: #004a7c;font-weight: bold;font-size: 1rem;">
                                                {{ rateCalculator(pickup_rate) }} %
                                            </span>
                                        </span>
                                    </label>
                                </div>

                                <div class="pickup-address-label">
                                    <span v-if="draft.pickup_address_1">
                                        {{ draft.pickup_address_1 }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_address_2">
                                        {{ draft.pickup_address_2 }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_suburb">
                                        {{ draft.pickup_suburb }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_city">
                                        {{ draft.pickup_city }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_state">
                                        {{ draft.pickup_state }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_postal_code">
                                        {{ draft.pickup_postal_code }},&nbsp;
                                    </span>
                                    <span v-if="draft.pickup_country">
                                        {{ draft.pickup_country }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider name="type of good" rules="required" v-slot="{ errors }" slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Type of Goods
                                            <span>*</span>
                                        </label>
                                        <select v-model="draft.type_of_good" @change="onPackageTypeChange($event)"
                                            :class="errors[0]
                                                ? 'warning-border'
                                                : ''
                                                " :disabled="enable_edit_var == false">
                                            <option value="" disabled>Select package type</option>
                                            <option v-for="(good, i) in goods" :key="'goods' + i" :value="good.id">{{
                                                good.type }}</option>
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider name="mode of dispatch" rules="required" v-slot="{ errors }" slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Service Type
                                            <span>*</span>
                                        </label>
                                        <select v-model="draft.carrier_service_type" :class="errors[0]
                                            ? 'warning-border'
                                            : ''
                                            "
                                            :disabled="(enable_edit_var == false && auth().user_type == 'user' && draft.is_default_courier_messenger) || (enable_edit_var && auth().user_type == 'user' && draft.is_default_courier_messenger)"
                                            @change="onServiceTypeChange($event)">
                                            <option value="" disabled>Select Service Type</option>
                                            <option v-for="(type,
                                                i) in servicetypes" :key="'types' + i" :value="type.id">{{
                                                    type.service_type }}</option>
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                            <template v-if="'Postal' == draft.delivery_service_type">
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Recipient Name
                                            <span>*</span>
                                        </label>
                                        <input type="text" :placeholder="`Enter Character Limit ${max6}`"
                                            :maxlength="max6" v-model="draft.recipient_name" />
                                    </div>
                                </td>
                            </template>
                        </tr>
                        <tr class="equal_3">
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Select from Address Books
                                    </label>
                                    <span style="margin-left: 3px;">
                                        <a @click="
                                            viewCompanyAddressBook(
                                                'delivery'
                                            )
                                            " v-if="enable_edit_var != false" class="delivery-address-label">
                                            COMPANY ADDRESS BOOK
                                        </a>
                                    </span>
                                    <span style="margin-left: 3px;">
                                        <a @click="
                                            viewPersonalAddressBook(
                                                'delivery'
                                            )
                                            " v-if="enable_edit_var != false" class="delivery-address-label">
                                            PERSONAL ADDRESS BOOK
                                        </a>
                                    </span>
                                    <span style="padding-left:5px;cursor:pointer;" v-if="rateCalculator(deliver_rate)">
                                        <span @click="viewAddressHistory(deliver_rate)"
                                            style="color: #004a7c;font-weight: bold;font-size: 1rem;">
                                            {{ rateCalculator(deliver_rate) }} %
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Street
                                    </label>
                                    <input type="text" placeholder="Street Address" v-model="draft.delivery_address_1"
                                        ref="delivery_address_1" @focus="
                                            fetchLocationApi(
                                                'delivery_address_1'
                                            )
                                            " autocomplete="new-password" :disabled="enable_edit_var == false" />
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Suburb
                                    </label>
                                    <input type="text" placeholder="Enter Character Limit 30"
                                        v-model="draft.delivery_suburb" @change="getRate('delivery')"
                                        autocomplete="new-password" :maxlength="max"
                                        :disabled="enable_edit_var == false" />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="label_and_element_wrapper no-form-text">
                                    <label>
                                        Delivery City
                                    </label>
                                    <input type="text" placeholder="Enter Character Limit 30"
                                        v-model="draft.delivery_city" @change="getRate('delivery')"
                                        autocomplete="new-password" :maxlength="max"
                                        :disabled="enable_edit_var == false" />
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper no-form-text">
                                    <label>
                                        Delivery State
                                    </label>
                                    <input type="text" placeholder="Enter Character Limit 30"
                                        v-model="draft.delivery_state" @change="getRate('delivery')"
                                        autocomplete="new-password" :maxlength="max"
                                        :disabled="enable_edit_var == false" />
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Delivery Postcode
                                    </label>
                                    <input type="text" placeholder="Enter Character Limit 30"
                                        v-model="draft.delivery_postal_code" @change="getRate('delivery')"
                                        autocomplete="new-password" :maxlength="max"
                                        :disabled="enable_edit_var == false" />
                                    <small class="form-text text-muted">Enter '00000' if the postal code is not
                                        available.</small>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <!-- Postal Delivery Service Type -->
                            <td v-if="draft.delivery_service_type === 'Postal'" class="postal-delivery v_align_top">
                                <div class="label_and_element_wrapper">
                                    <label>Reference Number</label>
                                    <input type="text" placeholder="Enter Character Limit 120"
                                        v-model="draft.reference_number" :maxlength="max5"
                                        :class="{ 'new-error': error_class }" :disabled="!enable_edit_var" />
                                </div>

                                <div class="user_type centered">
                                    <div class="radio-container">
                                        <h3 class="radio-heading">Client</h3>

                                        <div class="radio-options">
                                            <div class="radio-option">
                                                <label for="option-contract" class="radio-label">Contract</label>
                                                <input type="radio" id="option-contract" value="Contract"
                                                    v-model="draft.client" class="radio-input" />
                                            </div>

                                            <div class="radio-option">
                                                <label for="option-non-contract" class="radio-label">Non-contract</label>
                                                <input type="radio" id="option-non-contract" value="Non-contract"
                                                    v-model="draft.client" class="radio-input" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Non-Postal Delivery Service Type -->
                            <td v-else class="v_align_top">
                                <div class="radio-container">
                                    <h3 class="radio-heading">Priority</h3>

                                    <div class="radio-options">
                                        <div class="radio-option">
                                            <label for="option-standard" class="radio-label">Standard</label>
                                            <input type="radio" id="option-standard" value="Standard"
                                                v-model="draft.priority" class="radio-input" />
                                        </div>

                                        <div class="radio-option">
                                            <label for="option-express" class="radio-label">Express</label>
                                            <input type="radio" id="option-express" value="Express"
                                                v-model="draft.priority" class="radio-input" />
                                        </div>

                                        <div class="radio-option">
                                            <label for="option-immediate" class="radio-label">Immediate</label>
                                            <input type="radio" id="option-immediate" value="Immediate"
                                                v-model="draft.priority" class="radio-input" />
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Matter Number Section -->
                            <template v-if="'Non-contract' != draft.client">
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>Matter Number</label>
                                        <select-dropdown :id="draft.matter_number_id" :items="matter_numbers"
                                            @setSelected="setSelectedMatterNumber" />
                                    </div>
                                    <div class="label_and_element_wrapper">
                                        <label>{{ gl_code_placeholder }}</label>
                                        <div class="gl-input-group">
                                            <input type="text" class="gl-input-field" :placeholder="gl_code_placeholder"
                                                v-model="draft.gl_code" :disabled="disable_gl_code">
                                            <a v-if="'Non-contract' != draft.client" class="new_yellow_bt"
                                                @click="changeGlCode = true"
                                                style="background: #006A4E; width: 150px; margin-right: 0px">
                                                {{ hub_admin ? 'Select' : 'Change' }} GL
                                            </a>
                                        </div>
                                        <div v-if="hub_admin" class="d-flex">
                                            <label>
                                                PAL Code
                                                <input name="custom_code" type="radio"
                                                    @click="selectCustomCode('PAL Code')" />
                                            </label>
                                            <label>
                                                Other Management Code
                                                <input name="custom_code" type="radio"
                                                    @click="selectCustomCode('Other Management Code')" />
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </template>

                            <td class="v_align_top">
                                <div class="label_and_element_wrapper no-form-text">
                                    <label>
                                        Delivery Country
                                    </label>
                                    <select-dropdown v-if="lcountries && lcountries.length > 0"
                                        :id="draft.delivery_country_code" :items="lcountries"
                                        @setSelected="setSelectedCountry">
                                    </select-dropdown>
                                </div>
                            </td>
                        </tr>

                    </table>
                    <table v-if="'Postal' != draft.delivery_service_type && auth().user_type != 'user'">
                        <tr>
                            <td>
                                <header class="line-items-heading">
                                    <h2 class="_line_item">PACKAGE DETAILS</h2>
                                    <a class="new_yellow_bt" style="background: #004A7C; width:150px;margin-right: 0px"
                                        @click="addPackage" v-if="draft.delivery_service_type !== 'International'">ADD
                                        PACKAGE</a>
                                </header>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <section v-for="(onePackage, p) in draft.packages" :key="`line-item-${p}`"
                                    class="line-item-border">
                                    <span class="line-item-label">Package {{ p + 1 }}</span>
                                    <div class="_sur_container">
                                        <div class="_sur_flex_container">
                                            <div class="_sur_flex_boxes">
                                                <div class="label_and_element_wrapper">
                                                    <label>
                                                        Unit
                                                        <span></span>
                                                    </label>
                                                    <input type="text" placeholder="Enter Character Limit 5"
                                                        :maxlength="5" :disabled="true" style="width:60px;"
                                                        v-model="onePackage.unit_measurement" />
                                                </div>
                                            </div>

                                            <div class="_sur_flex_boxes">
                                                <ValidationProvider name="package.length" rules="required|max:60"
                                                    v-slot="{ errors }" slim>
                                                    <div class="label_and_element_wrapper">
                                                        <label>
                                                            L(cm)
                                                            <span>*</span>
                                                        </label>
                                                        <input type="number" placeholder="Enter length" :maxlength="5"
                                                            :disabled="!enable_edit_var" v-model="onePackage.length"
                                                            @input="updatePackageM3(p)" v-prevent-scroll />
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="_sur_flex_boxes">
                                                <ValidationProvider name="package.width" rules="required|max:60"
                                                    v-slot="{ errors }" slim>
                                                    <div class="label_and_element_wrapper">
                                                        <label>
                                                            W(cm)
                                                            <span>*</span>
                                                        </label>
                                                        <input type="number" placeholder="Enter Width" :maxlength="5"
                                                            :disabled="!enable_edit_var" v-model="onePackage.width"
                                                            @input="updatePackageM3(p)" v-prevent-scroll />
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="_sur_flex_boxes">
                                                <ValidationProvider name="package.height" rules="required|max:60"
                                                    v-slot="{ errors }" slim>
                                                    <div class="label_and_element_wrapper">
                                                        <label>
                                                            H(cm)
                                                            <span>*</span>
                                                        </label>
                                                        <input type="number" placeholder="Enter Height" :maxlength="5"
                                                            :disabled="!enable_edit_var" v-model="onePackage.height"
                                                            @input="updatePackageM3(p)" v-prevent-scroll />
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="_sur_flex_boxes">
                                                <div class="label_and_element_wrapper">
                                                    <label>
                                                        m³
                                                        <span></span>
                                                    </label>
                                                    <input type="number" placeholder="Enter Character Limit 5"
                                                        :maxlength="5" :disabled="true" v-model="onePackage.m3"
                                                        v-prevent-scroll />
                                                </div>
                                            </div>
                                            <div class="_sur_flex_boxes">
                                                <ValidationProvider name="item_weight" rules="required|max:60"
                                                    v-slot="{ errors }" slim>
                                                    <div class="label_and_element_wrapper">
                                                        <label>
                                                            Weight(kg)
                                                            <span>*</span>
                                                        </label>
                                                        <input type="number" placeholder="Enter Weight"
                                                            v-model="onePackage.weight" :maxlength="max1"
                                                            :disabled="enable_edit_var == false"
                                                            :class="errors[0] ? 'warning-border' : ''"
                                                            @change="updateTotalWeight(p)" v-prevent-scroll />
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="_sur_flex_boxes small action" v-if="p != 0">
                                                <a class="new_yellow_bt" style="background: red; width:100%;padding:0px"
                                                    @click="removePackage(p)">
                                                    <i class="material-icons">delete</i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="line-items" v-if="draft.delivery_service_type === 'International'">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <header class="packages-heading">
                                                            <a class="new_yellow_bt"
                                                                style="background: #004A7C; width:100px;margin-right:0px"
                                                                @click="addLineItems(p)">ADD ITEM</a>
                                                        </header>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <section v-for="(oneLineItem, i) in onePackage.lineItems"
                                                            :key="`line-item-${i}`" class="package-border">
                                                            <span class="package-label">Item {{ i + 1 }}</span>

                                                            <div class="_sur_container">
                                                                <div class="_sur_flex_container line-item-details">
                                                                    <div class="_sur_flex_boxes">
                                                                        <div class="label_and_element_wrapper">
                                                                            <label>
                                                                                Item Reference
                                                                                <span></span>
                                                                            </label>
                                                                            <input type="text"
                                                                                placeholder="Enter Character Limit 30"
                                                                                v-model="oneLineItem.item_reference"
                                                                                :maxlength="max"
                                                                                :disabled="enable_edit_var == false"
                                                                                style="width: 120px;" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="item description"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Item Description
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="text"
                                                                                    placeholder="Enter Character Limit 20"
                                                                                    v-model="oneLineItem.item_description"
                                                                                    :maxlength="max"
                                                                                    :class="errors[0] || (oneLineItem.item_description == '' || oneLineItem.item_description == null) ? 'warning-border' : ''"
                                                                                    :disabled="enable_edit_var == false"
                                                                                    style="width: 150px;" />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="item_type"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Quantity
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="number"
                                                                                    placeholder="Enter Quantity"
                                                                                    v-model="oneLineItem.item_qty"
                                                                                    :maxlength="max1"
                                                                                    :disabled="enable_edit_var == false"
                                                                                    :class="errors[0] ||
                                                                                        (
                                                                                            oneLineItem.item_qty == '' ||
                                                                                            oneLineItem.item_qty == null
                                                                                        )
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " v-prevent-scroll />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="item_weight"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Weight(Kg)
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="number"
                                                                                    v-model="oneLineItem.item_weight"
                                                                                    :maxlength="max1"
                                                                                    :disabled="enable_edit_var == false"
                                                                                    placeholder="Enter Weight"
                                                                                    :class="errors[0] || (oneLineItem.item_weight == '' || oneLineItem.item_weight == null) ? 'warning-border' : ''"
                                                                                    v-prevent-scroll />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes">
                                                                        <div class="label_and_element_wrapper">
                                                                            <label>
                                                                                Unit
                                                                                <span></span>
                                                                            </label>
                                                                            <input type="text"
                                                                                placeholder="Enter Character Limit 5"
                                                                                :maxlength="5" :disabled="true"
                                                                                style="width:60px;"
                                                                                v-model="oneLineItem.item_charge_unit" />
                                                                        </div>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="item_length"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    L(cm)
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="number"
                                                                                    placeholder="Enter Length"
                                                                                    :maxlength="5"
                                                                                    :disabled="!enable_edit_var"
                                                                                    v-model="oneLineItem.item_length"
                                                                                    :class="errors[0] || (oneLineItem.item_length == '' || oneLineItem.item_length == null) ? 'warning-borde' : ''"
                                                                                    @input="updatePackageItemM3(p, i, 'l', $event)"
                                                                                    v-prevent-scroll />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="item_width"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    W(cm)
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="number"
                                                                                    placeholder="Enter Width"
                                                                                    :maxlength="5"
                                                                                    :disabled="!enable_edit_var"
                                                                                    v-model="oneLineItem.item_width"
                                                                                    :class="errors[0] || (oneLineItem.item_width == '' || oneLineItem.item_width == null) ? 'warning-borde' : ''"
                                                                                    @input="updatePackageItemM3(p, i, 'w', $event)"
                                                                                    v-prevent-scroll />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="item_height"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    H(cm)
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="number"
                                                                                    placeholder="Enter Height"
                                                                                    :maxlength="5"
                                                                                    :disabled="!enable_edit_var"
                                                                                    v-model="oneLineItem.item_height"
                                                                                    :class="errors[0] || (oneLineItem.item_height == '' || oneLineItem.item_height == null) ? 'warning-borde' : ''"
                                                                                    @input="updatePackageItemM3(p, i, 'h', $event)"
                                                                                    v-prevent-scroll />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <div class="label_and_element_wrapper">
                                                                            <label>
                                                                                m³
                                                                                <span></span>
                                                                            </label>
                                                                            <input type="number"
                                                                                placeholder="Enter Character Limit 5"
                                                                                :maxlength="5" :disabled="true"
                                                                                v-model="oneLineItem.item_m3"
                                                                                v-prevent-scroll />
                                                                        </div>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes small action"
                                                                        v-if="i != 0">
                                                                        <a class="new_yellow_bt"
                                                                            style="background: red; width:100%;padding:0px"
                                                                            @click="removePackageItem(p, i)">
                                                                            <i class="material-icons">delete</i>
                                                                        </a>
                                                                    </div>
                                                                </div>

                                                                <div class="_sur_flex_container line-item-details"
                                                                    v-if="draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'">
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="export reason_type"
                                                                            rules="required" v-slot="{ errors }" slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Export Reason
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <select
                                                                                    v-model="oneLineItem.export_reason_type"
                                                                                    :maxlength="max1" :class="errors[0] || (oneLineItem.item_type == 'Parcel' &&
                                                                                        draft.carrier_code == 'DHL' &&
                                                                                        (oneLineItem.export_reason_type == '' || oneLineItem.export_reason_type == null))
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        "
                                                                                    :disabled="draft.delivery_service_type === 'International' && draft.package_type === 'Parcel' ? false : true">
                                                                                    <option
                                                                                        v-for="(reason, i) in export_reasons"
                                                                                        :key="'reason-' + i"
                                                                                        :value="reason.key">
                                                                                        {{ reason.name }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="export reason type"
                                                                            rules="required" v-slot="{ errors }" slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Manufacture
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <select
                                                                                    v-model="oneLineItem.country_code"
                                                                                    :maxlength="2" :class="errors[0] || (oneLineItem.item_type == 'Parcel' &&
                                                                                        draft.carrier_code == 'DHL' &&
                                                                                        oneLineItem.country_code == '')
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " :disabled="false">
                                                                                    <option
                                                                                        v-for="(country, i) in countries"
                                                                                        :key="'countries' + i"
                                                                                        :value="country.code">
                                                                                        {{ country.name }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <ValidationProvider name="product_code"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Commercial Value
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="text"
                                                                                    placeholder="Enter Value"
                                                                                    :maxlength="max"
                                                                                    v-model="oneLineItem.commercial_value"
                                                                                    :class="errors[0] || (oneLineItem.item_type == 'Parcel' &&
                                                                                        draft.carrier_code == 'DHL' &&
                                                                                        (oneLineItem.commercial_value == '' || oneLineItem.commercial_value == null))
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " :disabled="false" />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes">
                                                                        <div class="label_and_element_wrapper">
                                                                            <label>
                                                                                Insured Value
                                                                                <span></span>
                                                                            </label>
                                                                            <input type="text" placeholder="Enter Value"
                                                                                :maxlength="max" style="width: 105px;"
                                                                                v-model="oneLineItem.insured_value"
                                                                                :disabled="false" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="_sur_flex_boxes small">
                                                                        <ValidationProvider name="export_reason_type"
                                                                            rules="required" v-slot="{ errors }" slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Currency
                                                                                    <span></span>
                                                                                </label>
                                                                                <input type="text"
                                                                                    placeholder="Enter Character Limit 2"
                                                                                    :maxlength="3"
                                                                                    v-model="oneLineItem.currency"
                                                                                    :class="errors[0] || (oneLineItem.item_type == 'Parcel' &&
                                                                                        draft.carrier_code == 'DHL' &&
                                                                                        (oneLineItem.currency == '' || oneLineItem.currency == null))
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " :disabled="itemDisabled" />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                </div>

                                                                <div class="_sur_flex_container line-item-details"
                                                                    v-if="draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'">
                                                                    <div class="_sur_flex_boxes full-width">
                                                                        <ValidationProvider name="commodity code value"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Commodity code value
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="text"
                                                                                    placeholder="Enter value"
                                                                                    :minlength="5"
                                                                                    v-model="oneLineItem.commodity_code_value"
                                                                                    :disabled="false" :class="errors[0] || ((oneLineItem.commodity_code_value == '' || oneLineItem.commodity_code_value == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel')
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " />
                                                                            </div>
                                                                            <a :href="hs_code_link" class="hs_code_link"
                                                                                target="_blank">Find commodity code
                                                                                value here</a>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes full-width">
                                                                        <ValidationProvider
                                                                            name="customer ref type code"
                                                                            rules="required" v-slot="{ errors }" slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Customer Ref. Type Code
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <select
                                                                                    v-model="oneLineItem.customer_ref_type_code"
                                                                                    :maxlength="max1" :class="errors[0] || ((oneLineItem.customer_ref_type_code == '' || oneLineItem.customer_ref_type_code == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel')
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " :disabled="false">
                                                                                    <option
                                                                                        v-for="(code, i) in customer_ref_type_codes"
                                                                                        :key="'customer-ref-type-code-' + i"
                                                                                        :value="code.key">
                                                                                        {{ code.description || code.key
                                                                                        }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes full-width">
                                                                        <ValidationProvider
                                                                            name="customer ref code value"
                                                                            rules="required|max:60" v-slot="{ errors }"
                                                                            slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Customer Ref. Code Value
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <input type="text"
                                                                                    placeholder="Enter value"
                                                                                    :minlength="2"
                                                                                    v-model="oneLineItem.customer_ref_type_value"
                                                                                    :disabled="false" :class="errors[0] || ((oneLineItem.customer_ref_type_value == '' || oneLineItem.customer_ref_type_value == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel')
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " />
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div class="_sur_flex_boxes full-width">
                                                                        <ValidationProvider
                                                                            name="customer ref type code"
                                                                            rules="required" v-slot="{ errors }" slim>
                                                                            <div class="label_and_element_wrapper">
                                                                                <label>
                                                                                    Unit Measurement
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <select
                                                                                    v-model="oneLineItem.unit_measurement"
                                                                                    :maxlength="max1" :class="errors[0] || ((oneLineItem.unit_measurement == '' || oneLineItem.unit_measurement == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel')
                                                                                        ? 'warning-border'
                                                                                        : ''
                                                                                        " :disabled="false">
                                                                                    <option
                                                                                        v-for="(measurement, i) in unit_measurements"
                                                                                        :key="'customer-ref-type-code-' + i"
                                                                                        :value="measurement.key">
                                                                                        {{ measurement.name }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div
                                                                        class="_sur_flex_boxes full-width justfy-content-center">
                                                                        <ValidationProvider name="is tax paid"
                                                                            rules="required" v-slot="{ errors }" slim>

                                                                            <div
                                                                                class="label_and_element_wrapper d-flex">

                                                                                <label class="min-width-0">
                                                                                    Is tax Paid
                                                                                    <span>*</span>
                                                                                </label>
                                                                                <div class="d-flex">
                                                                                    <label class="min-width-70">
                                                                                        <input type="radio"
                                                                                            :name="`is_tax_paid-${p}-${i}`"
                                                                                            value="true"
                                                                                            v-model="oneLineItem.is_tax_paid">
                                                                                        Yes
                                                                                    </label>
                                                                                    <label class="min-width-70">
                                                                                        <input type="radio"
                                                                                            :name="`is_tax_paid-${p}-${i}`"
                                                                                            value="false"
                                                                                            v-model="oneLineItem.is_tax_paid">
                                                                                        No
                                                                                    </label>
                                                                                </div>

                                                                            </div>

                                                                        </ValidationProvider>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </section>
                            </td>
                        </tr>
                    </table>
                    <table style="margin-top: 20px">
                        <tr>
                            <td v-if="!findcourier_btn.disabled && !findcourier_btn.length"></td>

                            <td v-if="!findcourier_btn.disabled && findcourier_btn.length && ratecouriers.length == 0"
                                class="find-couriers-container">
                                <div class="find-couriers-preloader preloader-container">
                                    <p class="preloader-text">No Couriers Found...</p>
                                </div>
                            </td>

                            <td v-if="findcourier_btn.disabled" class="find-couriers-container">
                                <div class="find-couriers-preloader">
                                    <div class="loader-container">
                                        <img src="../../assets/loader.svg" class="loader-image" />
                                        <p class="loader-message">Finding the Best Couriers for you. Please Wait...</p>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <template v-if="'Postal' != draft.delivery_service_type">
                                    <a class="new_yellow_bt" @click="findCouriers"
                                        :class="['new_yellow_bt', { 'disabled': findcourier_btn.disabled }]"
                                        style="margin-right: 0px" :aria-disabled="findcourier_btn.disabled" v-if="enable_edit_var != false
                                            && auth().user_type != 'user'
                                        ">{{ findcourier_btn.value }}</a>
                                </template>
                                <a v-else href="javascript:void(0)" class="new_yellow_bt" @click="savePostalQuote">
                                    {{ save_postal_btn.value }}
                                </a>
                                <div v-if="isDisabled">
                                    <a class="new_yellow_bt" @click="fetchAvailableServices"
                                        style="background: #004A7C;" :disabled="available_service_btn.disable">{{
                                            available_service_btn.value }}</a>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <div class="form_section1" v-if="isDisabled">
                        <div class="h2_wiith_icons">
                            <div class="adj_cover">
                                <h2>Services</h2>
                                <div class="adj_in">
                                    <i class="material-icons">info</i>
                                    <div class="info_box">
                                        <header>Courier Options</header>
                                        <section>
                                            Select the appropriate carrier for you
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="blue-box">
                            <p>Please select the service you require for your booking from the list below. The Mailroom
                                will complete your
                                booking and ensure that the most appropriate service has been selected. For all courier
                                bookings after 6.00
                                PM, please contact the Mailroom on (02) 9241 1853 - you will be redirected to the KWM
                                After Hours Service
                                who will assist you in making your booking</p>
                        </div>

                        <div class="user_service_selection">
                            <header>
                                <div class="">
                                    <div>Available Services</div>
                                    <div></div>
                                </div>
                            </header>
                            <div v-for="(availableCourierService, i) in availableCourierServices" :key="i">
                                <div class="user_service_selection_row row"
                                    v-for="(availableCourierServiceName, i) in availableCourierService.servicenames"
                                    :key="i">
                                    <div class="col">
                                        <div>{{ availableCourierService.name }} - {{ availableCourierServiceName.name }}
                                            ({{
                                                availableCourierServiceName.priority }})</div>
                                    </div>

                                    <div class="col">
                                        <button class="select_btn"
                                            :class="{ 'selected': draft.user_selected_services.includes(availableCourierServiceName.id) }"
                                            @click="toggleServiceSelection(availableCourierServiceName.id)">
                                            {{ draft.user_selected_services.includes(availableCourierServiceName.id) ? 'Selected' : 'Select Service' }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="total-services">
                                <div> {{ getServiceCount }} Total Services</div>
                                <div> Other Services may be available</div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div class="form_section1" v-if="isUserBooking">
                <div class="user_service_selection">
                    <header>
                        <div class="">
                            <div>User Selected Service</div>
                            <div></div>
                        </div>
                    </header>

                    <div class="user_service_selection_row row" v-for="(userSelectedService, i) in userSelectedServices"
                        :key="i">
                        <div class="col">
                            <div>{{ userSelectedService.provider }} - {{ userSelectedService.Service_name }} ({{
                                userSelectedService.priority }})</div>
                        </div>
                    </div>
                </div>
            </div>

            <section
                v-if="(courier_list == true || edit_mode == true) && auth().user_type != 'user' && 'Postal' != draft.delivery_service_type">
                <div class="h2_wiith_icons">
                    <div class="adj_cover">
                        <h2>COURIER OPTIONS</h2>
                        <div class="adj_in">
                            <i class="material-icons">info</i>
                            <div class="info_box">
                                <header>Courier Options</header>
                                <section>
                                    Compare carrier results and select the
                                    appropriate carrier
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_section1">
                    <div class="special_panel_2">
                        <header>
                            <div class="title_cover">Delivery Partner</div>
                            <div class="columns_cover">
                                <div>Service Name</div>
                                <div>Transit Time</div>
                                <div>Special Notes</div>
                                <div>Courier Cost</div>
                            </div>
                        </header>
                        <div class="courier_row " v-bind:class="ratecourier.is_carrier_preference == 1
                            ? ''
                            : ''
                            " v-for="(ratecourier, i) in ratecouriers" :key="'ratecourier' + i">
                            <div class="main_cover">
                                <label class="logo_selected">
                                    <img :src="ratecourier.logo" :alt="ratecourier.name" />
                                    <div class="radio_adj">
                                        <input type="radio" name="selected_courier" @click="SelectCourier(ratecourier)"
                                            :checked="ratecourier.is_checked" />
                                    </div>
                                </label>
                                <div class="content_selected">
                                    {{ ratecourier.service_provider_description || ratecourier.description }}
                                </div>
                            </div>
                            <div class="columns_cover">
                                <div>{{ ratecourier.service_name }}</div>
                                <div>
                                    {{ ratecourier.service_provider_transit_days || ratecourier.transit_time }}&nbsp;
                                </div>

                                <div>
                                    <template v-if="ratecourier.service_provider_logo">
                                        <img :src="ratecourier.service_provider_logo" :alt="ratecourier.name" />
                                    </template>
                                    <template v-else>
                                        <a class="view_doc" :href="ratecourier.sla" target="_blank"
                                            v-if="ratecourier.sla.includes('SLA')">
                                            Notes
                                        </a>
                                        <span class="view_doc" v-if="!ratecourier.sla.includes('SLA')">
                                            No Files
                                        </span>
                                    </template>
                                </div>

                                <div class="ratecourier-cost">
                                    <div class="sub_info">
                                        ₱ {{ ratecourier.cost }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="form_section1" v-if="isUserBooking">
                    <table>
                        <tr>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Enter Comment for user selected services:
                                    </label>
                                    <textarea placeholder="Add comment Here"
                                        v-model="draft.user_delivery_service_comment" :maxlength="max6"></textarea>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </section>

            <section v-show="draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'">
                <div class="h2_wiith_icons">
                    <div class="adj_cover">
                        <h2>INVOICE DETAILS</h2>
                        <div class="adj_in">
                            <i class="material-icons">info</i>

                            <div class="info_box">
                                <header>Quote</header>
                                <section>
                                    Provide the needful invoice data for book a international booking
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form_section1">
                    <table>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider name="payer vat number" rules="required|max:100" v-slot="{ errors }"
                                    slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Payer VAT Number
                                        </label>
                                        <input type="text" placeholder="Enter VAT Number Here"
                                            v-model="draft.payer_vat_number" :maxlength="max4" :disabled="isDisabled" />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Recipient Reference
                                    </label>
                                    <input type="text" placeholder="Enter Recipient Reference Here"
                                        v-model="draft.recipient_reference" :maxlength="max4" :disabled="isDisabled" />
                                </div>
                            </td>
                            <td>
                                <ValidationProvider name="shipment type" rules="required" v-slot="{ errors }" slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Shipment Type
                                            <span>*</span>
                                        </label>
                                        <select v-model="draft.shipment_type" :class="(draft.shipment_type == '' || draft.shipment_type == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'
                                            ? 'warning-border'
                                            : ''
                                            " :disabled="isDisabled">
                                            <option value="" disabled>Select a shipment type</option>
                                            <option v-for="(type, i) in shipment_types" :key="'shipment-type-' + i"
                                                :value="type">{{
                                                    capitalizeFirstLetter(type) }}
                                            </option>
                                        </select>
                                    </div>
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider name="signature name" rules="required|max:100" v-slot="{ errors }"
                                    slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Signature Name
                                            <span>*</span>
                                        </label>
                                        <input type="text" placeholder="Enter Signature Name Here"
                                            v-model="draft.signature_name" :maxlength="max4" :class="(draft.signature_name == '' || draft.signature_name == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'
                                                ? 'warning-border'
                                                : ''
                                                " :disabled="isDisabled" />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider name="signature title" rules="required|max:100" v-slot="{ errors }"
                                    slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Signature Title
                                            <span>*</span>
                                        </label>
                                        <input type="text" placeholder="Enter Signature Title Here"
                                            v-model="draft.signature_title" :maxlength="max4" :class="(draft.signature_title == '' || draft.signature_title == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'
                                                ? 'warning-border'
                                                : ''
                                                " :disabled="isDisabled" />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                        <tr class="equal_3">
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Gross Weight (Kg)
                                    </label>
                                    <input type="text" placeholder="Enter Signature Name Here"
                                        v-model="draft.gross_weight" :maxlength="max4" :disabled="isDisabled"
                                        :readonly="true" />
                                </div>
                            </td>
                            <td>
                                <div class="label_and_element_wrapper">
                                    <label>
                                        Net Weight (Kg)
                                    </label>
                                    <input type="text" placeholder="Enter Signature Title Here"
                                        v-model="draft.net_weight" :maxlength="max4" :disabled="isDisabled"
                                        :readonly="true" />
                                </div>
                            </td>
                        </tr>
                        </td>
                        </tr>
                        <tr class="equal_3">
                            <td>
                                <ValidationProvider name="invoice_no" rules="max:60" v-slot="{ errors }" slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Invoice No
                                        </label>
                                        <input type="text" placeholder="Enter Character Limit 60" :maxlength="60"
                                            :disabled="!(draft.delivery_service_type === 'International' && draft.package_type === 'Parcel')"
                                            v-model="draft.invoice_no" />
                                    </div>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider name="invoice_date" rules="" v-slot="{ errors }" slim>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Invoice Date
                                        </label>
                                        <flat-pickr class="date_for_pos_fixed" :config="configDate"
                                            v-model="draft.invoice_date" placeholder="Date" name="invoice_date"
                                            :disabled="!(draft.delivery_service_type === 'International' && draft.package_type === 'Parcel')"></flat-pickr>
                                    </div>
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </div>
            </section>

            <div v-if="'Postal' != draft.delivery_service_type" class="h2_wiith_icons">

                <div class="adj_cover">
                    <h2>ADDRESS & SHIPMENT DETAILS</h2>
                    <div class="adj_in">
                        <i class="material-icons">info</i>

                        <div class="info_box">
                            <header>Address & Shipment Details</header>
                            <section>
                                Book shipment and generate the label for
                                shipment pickup
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <section class="new_flexed_class" v-if="'Postal' != draft.delivery_service_type">
                <div class="address_details_wrapper flex_adj">
                    <header>
                        <h2>PICKUP DETAILS</h2>
                    </header>
                    <div class="form_section1">
                        <table>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="pickup name" :rules="`required|max:${max3}`"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Full Name
                                                <span>*</span>
                                            </label>
                                            <input type="text" :placeholder="`Enter Character Limit ${max3}`"
                                                v-model="draft.pickup_name" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_name == '' || draft.pickup_name == null)
                                                )
                                                    ? 'dynamic_data_trigger warning-border'
                                                    : 'dynamic_data_trigger'
                                                    " :disabled="enable_edit_var == false"
                                                @focus="show_pickup_suggestion = true" @blur="hideSuggestions"
                                                autocomplete="new-password" :maxlength="max3" />
                                            <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion">
                                                <span v-for="(address,
                                                    i) in filteredPickupAddress" :key="'pickup_address_' + i"
                                                    @click="populatePickupAddress(address)">
                                                    {{ address.name }}
                                                </span>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="pickup company name" :rules="`required|max:${max3}`"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Company Name
                                                <span>*</span>
                                            </label>
                                            <input type="text" :placeholder="`Enter Character Limit ${max3}`"
                                                v-model="draft.pickup_company" autocomplete="new-password" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_company == '' || draft.pickup_company == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :maxlength="max3" :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="pickup email" rules="required|max:60" v-slot="{ errors }"
                                        slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Email
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_email" autocomplete="new-password"
                                                :maxlength="max1" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_email == '' || draft.pickup_email == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="pickup address_1" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Address 1
                                                <span>*</span>
                                                <em class="lite-color">Building</em>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 60"
                                                v-model="draft.pickup_address_1" autocomplete="new-password"
                                                @change="getRate('pickup')" :maxlength="max1" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_address_1 == '' || draft.pickup_address_1 == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="isDisabled" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Address 2
                                            <em class="lite-color">Street Landmark</em>
                                            <span v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                        </label>
                                        <input type="text" placeholder="Enter Character Limit 30"
                                            v-model="draft.pickup_address_2" @change="getRate('pickup')"
                                            autocomplete="new-password" :maxlength="max1" :disabled="isDisabled" :class="draft.carrier_code == 'DHL' && (draft.pickup_address_2 == '' || draft.pickup_address_2 == null)
                                                ? 'warning-border'
                                                : ''
                                                " />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="pickup suburb" rules="required|max:30" v-slot="{ errors }"
                                        slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Suburb
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.pickup_suburb" @change="getRate('pickup')"
                                                autocomplete="new-password" :maxlength="max" :disabled="isDisabled"
                                                :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_suburb == '' || draft.pickup_suburb == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <ValidationProvider name="pickup city" rules="required|max:30" v-slot="{ errors }"
                                        slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                City
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.pickup_city" @change="getRate('pickup')"
                                                autocomplete="new-password" :maxlength="max" :class="draft.carrier_code == 'DHL' && (draft.pickup_city == '' || draft.pickup_city == null)
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="isDisabled" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="pickup state" rules="required|max:30" v-slot="{ errors }"
                                        slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                State
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.pickup_state" @change="getRate('pickup')"
                                                autocomplete="new-password" :maxlength="max" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_state == '' || draft.pickup_state == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="isDisabled" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <ValidationProvider name="pickup postal_code" rules="required|max:10"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Postal Code
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 10"
                                                v-model="draft.pickup_postal_code" @change="getRate('pickup')"
                                                autocomplete="new-password" :maxlength="max2" :disabled="isDisabled"
                                                :class="['ZOOM2U', 'STC', 'COURIERSPLEASE'].includes(draft.carrier_code) && (draft.pickup_postal_code == '' || draft.pickup_postal_code == null)
                                                    ? 'warning-border'
                                                    : ''
                                                    " />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="pickup country" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Country
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.pickup_country" @change="getRate('pickup')"
                                                autocomplete="new-password" :maxlength="max" :disabled="isDisabled"
                                                :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_country == '' || draft.pickup_country == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="pickup phone" rules="required|max:30" v-slot="{ errors }"
                                        slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Phone
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="+61XXXXXXXXX" v-model="draft.pickup_phone"
                                                autocomplete="new-password" :maxlength="max" :disabled="isDisabled"
                                                :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.pickup_phone == '' || draft.pickup_phone == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Mobile
                                            <span v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                        </label>
                                        <input type="text" placeholder="+61XXXXXXXXX" v-model="draft.pickup_mobile"
                                            autocomplete="new-password" :maxlength="max" :disabled="isDisabled" :class="draft.carrier_code == 'DHL' && (draft.pickup_mobile == '' || draft.pickup_mobile == null)
                                                ? 'warning-border'
                                                : ''
                                                " />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Branch Name
                                        </label>
                                        <input type="text" placeholder="Enter Character Limit 30"
                                            v-model="draft.pickup_branch" autocomplete="new-password" :maxlength="max"
                                            :disabled="isDisabled" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="address_details_wrapper flex_adj">
                    <header>
                        <h2>DELIVERY DETAILS</h2>
                    </header>
                    <div class="form_section1">
                        <table>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="delivery name" :rules="`required|max:${max3}`"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Full Name
                                                <span>*</span>
                                            </label>
                                            <input type="text" :placeholder="`Enter Character Limit ${max3}`"
                                                v-model="draft.delivery_name" @focus="
                                                    show_destination_suggestion = true
                                                    " @blur="hideSuggestions" autocomplete="new-password"
                                                :maxlength="max3" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.delivery_name == '' || draft.delivery_name == null)
                                                )
                                                    ? 'dynamic_data_trigger warning-border'
                                                    : 'dynamic_data_trigger'
                                                    " :disabled="enable_edit_var == false" />
                                            <div class="dynamic_data_wrapper" v-if="show_destination_suggestion">
                                                <span v-for="(address, i) in filteredDeliveryAddress"
                                                    :key="'delivery_address_' + i"
                                                    @click="populateDeliveryAddress(address)">
                                                    {{ address.name }}
                                                </span>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Company Name
                                        </label>
                                        <input type="text" :placeholder="`Enter Character Limit ${max3}`"
                                            v-model="draft.delivery_company" autocomplete="new-password"
                                            :maxlength="max3" :disabled="enable_edit_var == false" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="delivery email" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Email
                                                <span
                                                    v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_email" autocomplete="new-password"
                                                :maxlength="max1" :class="['DHL'].includes(draft.carrier_code) && (draft.delivery_email == '' || draft.delivery_email == null)
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <ValidationProvider name="delivery address_1" rules="required|max:60"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Address 1
                                                <span>*</span>
                                                <em class="lite-color">Building</em>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 60"
                                                v-model="draft.delivery_address_1" autocomplete="new-password" :class="errors[0] || (
                                                    ['DHL','DBL'].includes(draft.carrier_code) &&
                                                    (draft.delivery_address_1 == '' || draft.delivery_address_1 == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :maxlength="max1" :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Address 2
                                            <em class="lite-color">Street Landmark</em>
                                            <span v-if="['DHL'].includes(draft.carrier_code)">*</span>
                                        </label>

                                        <input type="text" placeholder="Enter Character Limit 60"
                                            v-model="draft.delivery_address_2" autocomplete="new-password"
                                            :maxlength="max1" :disabled="enable_edit_var == false" :class="draft.carrier_code == 'DHL' && (draft.delivery_address_2 == '' || draft.delivery_address_2 == null)
                                                ? 'warning-border'
                                                : ''
                                                " />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="delivery suburb" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Suburb
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.delivery_suburb" @change="getRate('delivery')"
                                                autocomplete="new-password" :maxlength="max" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.delivery_suburb == '' || draft.delivery_suburb == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <ValidationProvider name="delivery city" rules="required|max:30" v-slot="{ errors }"
                                        slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                City
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.delivery_city" @change="getRate('delivery')"
                                                autocomplete="new-password" :class="draft.carrier_code == 'DHL' &&
                                                    (
                                                        draft.delivery_city == '' ||
                                                        draft.delivery_city == null
                                                    )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :maxlength="max" :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="delivery state" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                State
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.delivery_state" @change="getRate('delivery')"
                                                autocomplete="new-password" :maxlength="max" :class="errors[0] || (
                                                    ['DHL','DBL'].includes(draft.carrier_code) &&
                                                    (draft.delivery_state == '' || draft.delivery_state == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <ValidationProvider name="delivery postal_code" rules="required|max:10"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Postal Code
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 10"
                                                v-model="draft.delivery_postal_code" @change="getRate('delivery')"
                                                autocomplete="new-password" :maxlength="max2"
                                                :disabled="enable_edit_var == false" :class="errors[0] || (
                                                    ['DBL'].includes(draft.carrier_code) &&
                                                    (draft.delivery_postal_code == '' || draft.delivery_postal_code == null)
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " />
                                        </div>
                                    </ValidationProvider>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="delivery country" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Country
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="Enter Character Limit 30"
                                                v-model="draft.delivery_country" @change="getRate('delivery')"
                                                autocomplete="new-password" :maxlength="max" :disabled="enable_edit_var == false
                                                    " :class="errors[0] || (
                                                        ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                        (draft.delivery_country == '' || draft.delivery_country == null)
                                                    )
                                                        ? 'warning-border'
                                                        : ''
                                                        " />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Add to Address Book
                                        </label>
                                        <input type="radio" value="internal" v-model="save_address.internal"
                                            :checked="save_address.internal" />Internal
                                        <input type="radio" value="external" v-model="save_address.external"
                                            :checked="save_address.internal" />External
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ValidationProvider name="delivery phone" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Phone
                                                <span>*</span>
                                            </label>
                                            <input type="text" placeholder="+61XXXXXXXXX" v-model="draft.delivery_phone"
                                                autocomplete="new-password" :maxlength="max" :class="errors[0] || (
                                                    ['DHL', 'DBL'].includes(draft.carrier_code) &&
                                                    (draft.delivery_phone == null || draft.delivery_phone == '')
                                                )
                                                    ? 'warning-border'
                                                    : ''
                                                    " :disabled="enable_edit_var == false" />
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Mobile
                                            <span v-if="draft.carrier_code == 'DHL'">*</span>
                                        </label>
                                        <input type="text" placeholder="+61XXXXXXXXX" v-model="draft.delivery_mobile"
                                            autocomplete="new-password" :maxlength="max"
                                            :disabled="enable_edit_var == false" :class="draft.carrier_code == 'DHL' && (draft.delivery_mobile == '' || draft.delivery_mobile == null)
                                                ? 'warning-border'
                                                : ''
                                                " />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Branch Name
                                        </label>
                                        <input type="text" placeholder="Enter Character Limit 30"
                                            v-model="draft.delivery_branch" autocomplete="new-password" :maxlength="max"
                                            :disabled="enable_edit_var == false" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </section>
            <section class="new_flexed_class" v-if="'Postal' != draft.delivery_service_type">
                <div class="address_details_wrapper flex_adj">
                    <div class="form_section1">
                        <table>
                            <tr>
                                <td>

                                    <div v-if="draft.is_courier_pickup_date_required">
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Shipment Pickup Date
                                                <span>*</span>
                                            </label>
                                            <flat-pickr class="date_for_pos_fixed" v-model="draft.pickup_date"
                                                :config="configDate" placeholder="Date" name="birthdate"
                                                :disabled="enable_edit_var == false"
                                                @on-change="checkDateIfToday($event)"></flat-pickr>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="draft.is_courier_pickup_time_required">
                                        <ValidationProvider name="pickup city" rules="required|max:30"
                                            v-slot="{ errors }" slim>
                                            <div class="label_and_element_wrapper">
                                                <label>
                                                    Shipment Pickup Time
                                                    <span>*</span>
                                                </label>
                                                <flat-pickr :config="configTime" placeholder="Select Time"
                                                    name="birthtime" v-model="draft.pickup_time"
                                                    :disabled="enable_edit_var == false"></flat-pickr>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </td>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            <input type="checkbox" v-model="today_pickup" :checked="today_pickup"
                                                @change="toggleTodayPickup($event)" />
                                            Pickup Today
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="address_details_wrapper flex_adj">
                    <div class="form_section1">
                        <table>
                            <tr>
                                <td>
                                    <ValidationProvider name="delivery state" rules="required|max:30"
                                        v-slot="{ errors }" slim>
                                        <div class="label_and_element_wrapper">
                                            <label>
                                                Office Closed By
                                                <span>*</span>
                                            </label>
                                            <flat-pickr v-model="draft.office_close_time" :config="configTime"
                                                placeholder="Select Time" name="birthtime"
                                                :disabled="enable_edit_var == false"></flat-pickr>
                                        </div>
                                    </ValidationProvider>
                                </td>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Customer Reference Number
                                        </label>
                                        <input type="text" placeholder="Enter Character Limit 10"
                                            v-model="draft.reference_no" autocomplete="new-password" :maxlength="max2"
                                            :disabled="enable_edit_var == false" @change="customerRefenceNo()" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </section>

            <section class="new_flexed_class" v-if="'Postal' != draft.delivery_service_type">
                <div class="address_details_wrapper flex_adj">
                    <header>
                        <h2>DESCRIPTION OF GOODS</h2>
                    </header>

                    <div class="form_section1">
                        <table>
                            <tr>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Enter description of goods
                                            <span
                                                v-if="draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'">*</span>
                                        </label>
                                        <textarea placeholder="Enter Character Limit 70"
                                            v-model="draft.goods_description" :maxlength="max3" :class="(draft.goods_description == '' || draft.goods_description == null) && draft.delivery_service_type === 'International' && draft.package_type === 'Parcel'
                                                ? 'warning-border'
                                                : ''
                                                "></textarea>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="address_details_wrapper flex_adj" v-if="'Postal' != draft.delivery_service_type">
                    <header>
                        <h2>PICKUP INSTRUCTIONS</h2>
                    </header>
                    <div class="form_section1">
                        <table>
                            <tr>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Enter pickup instructions
                                        </label>
                                        <textarea placeholder="Description here Character Limit 70"
                                            v-model="draft.pickup_instructions" :maxlength="max3"></textarea>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="address_details_wrapper flex_adj" v-if="'Postal' != draft.delivery_service_type">
                    <header>
                        <h2>DELIVERY INSTRUCTIONS</h2>
                    </header>
                    <div class="form_section1">
                        <table>
                            <tr>
                                <td>
                                    <div class="label_and_element_wrapper">
                                        <label>
                                            Enter delivery instructions
                                        </label>
                                        <textarea placeholder="Description here Character Limit 70"
                                            v-model="draft.remarks" :maxlength="max3"></textarea>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </section>
        </ValidationObserver>
        <section class="centered" v-if="'Postal' != draft.delivery_service_type">
            <a class="link_bt bt_cancel" @click="$router.push('/pages/Dashboard')">Cancel</a>
            <a class="link_bt bt_draft" @click="draftsubmit" :disabled="save_btn.disabled">{{ save_btn.value }}</a>
            <a :class="['link_bt', 'bt_save', { 'disabled': dispatchsubmit_btn.disabled }]"
                :aria-disabled="dispatchsubmit_btn.disabled" @click="thresholdcheckdipatchsubmit"
                :disabled="dispatchsubmit_btn.disabled">{{ dispatchsubmit_btn.value }}</a>
        </section>
        <div class="black-overlay address-book-modal" v-if="
            company_address_book.pickup ||
            company_address_book.delivery ||
            personal_address_book.pickup ||
            personal_address_book.delivery
        ">
            <div class="box-modal">
                <div class="body">
                    <AddressBookModal :selectedAddresses="[
                        { id: draft.temp_pickup_address_id },
                    ]" v-if="company_address_book.pickup" @closeModal="closeModal" type="pickup"
                        @selectedAddress="populatePickupAddress" />
                    <AddressBookModal v-if="company_address_book.delivery" @closeModal="closeModal" type="delivery"
                        :callfrom="draft.entry_type" @selectedAddress="populateDeliveryAddress"
                        :selectedAddresses="draft.delivery_address" />
                    <PersonalAddressBookModal :selectedAddresses="[
                        { id: draft.temp_pickup_address_id },
                    ]" v-if="personal_address_book.pickup" @closeModal="closeModal" type="pickup"
                        @selectedAddress="populatePickupAddress" />
                    <PersonalAddressBookModal v-if="personal_address_book.delivery" @closeModal="closeModal"
                        type="delivery" :callfrom="draft.entry_type" @selectedAddress="populateDeliveryAddress"
                        :selectedAddresses="draft.delivery_address" />
                </div>
            </div>
        </div>
        <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
            <div class="box-modal printPops">
                <div class="body">
                    <ViewConsignment @closeModal="closeConsignment" :booking_id="viewconsignmentpopup_booking_id"
                        v-if="viewconsignmentpopup_booking_id" />
                </div>
            </div>
        </div>
        <div class="black-overlay" v-if="show_approval_reference == true">
            <div class="box-modal smallPops">
                <div class="body">
                    <ApprovalReferencePopup @closeModal="closeModalApprovalReference"
                        @submitmanager="populateManagerName" :managerName="draft.manager_name" />
                </div>
            </div>
        </div>
        <!-- change pickup address -->
        <div class="black-overlay" v-if="changePickup">
            <div class="box-modal" style="background:#004a7c; height: 750px;">
                <div class="body" style="height: 730px;">
                    <ChangePickupAddress v-if="changePickup" :countries="lcountries" @closeModal="changePickup = false"
                        @submit="populatePickupAddressApi" />
                </div>
            </div>
        </div>
        <!-- change gl code -->
        <div class="black-overlay" v-if="changeGlCode">
            <div class="box-modal smallPops" style="background:#004a7c;">
                <div class="body">
                    <ChangeGlcode v-if="changeGlCode" @closeModal="changeGlCode = false" @submit="populateGlCodeApi" />
                </div>
            </div>
        </div>
        <!-- change pickup address -->
        <div class="black-overlay" v-if="addressHistoryList">
            <div class="box-modal" style="background:#004a7c; height: 750px;">
                <div class="body" style="height: 730px;">
                    <AddressHistoryList v-if="addressHistoryList" :showAddressList="showAddressList"
                        @closeModal="addressHistoryList = false" />
                </div>
            </div>
        </div>
        <div class="black-overlay" v-show="viewconsignmentpopup_booking_id">
            <div class="box-modal printPops">
                <div class="body">
                    <ViewConsignment @closeModal="closeConsignment" :booking_id="viewconsignmentpopup_booking_id"
                        :booking_prefix="viewconsignmentpopup_booking_prefix" v-if="viewconsignmentpopup_booking_id" />
                </div>
            </div>
        </div>

        <div class="black-overlay" v-show="handling_fee_percentage">
            <div class="box-modal printPops">
                <div class="body">
                    <PriceBreakdownPopupModal @closeModal="ClosePriceBreakdownPopupModal"
                        :handling_fee_percentage="handling_fee_percentage" :price_without_GST="price_without_GST"
                        :handling_fee="handling_fee" :total_price="total_price" :gst_value="gst_value"
                        :total_price_with_gst="total_price_with_gst" :fuel_surcharge="fuel_surcharge"
                        v-if="handling_fee_percentage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import flatPickr from "vue-flatpickr-component";
import ChangePickupAddress from "./ChangePickupAddress.vue";
import AddressBookModal from "./AddressBookModal.vue";
import PersonalAddressBookModal from "./PersonalAddressBookModal.vue";
import PriceBreakdownPopupModal from "./PriceBreakdownPopupModal.vue";
import ViewConsignment from "./ViewConsignment.vue";
import ApprovalReferencePopup from "./ApprovalReferencePopup.vue";
import AddressHistoryList from "./AddressHistoryList.vue";
import moment from "moment-timezone";
import ChangeGlcode from "./ChangeGlCode.vue";
import jQuery from "jquery";
import "flatpickr/dist/flatpickr.css";

const $ = jQuery;

export default {
    name: "QuoteAndBook",
    components: {
        flatPickr,
        ChangePickupAddress,
        AddressHistoryList,
        AddressBookModal,
        PersonalAddressBookModal,
        PriceBreakdownPopupModal,
        ViewConsignment,
        ApprovalReferencePopup,
        ChangeGlcode,
    },
    computed: {
        filteredPickupAddress() {
            return this.address_list.filter((element) => {
                if (element.name.toLowerCase().includes(this.draft.pickup_name.toLowerCase())) {
                    return element;
                }
            });
        },
        filteredDeliveryAddress() {
            return this.address_list.filter((element) => {
                if (element.name.toLowerCase().includes(this.draft.delivery_name.toLowerCase())) {
                    return element;
                }
            });
        },
        filteredRequesterAddress() {
            return this.address_list.slice().filter((element) => {
                if (element.name.toLowerCase().includes(this.draft.requester_name.toLowerCase())) {
                    return element;
                }
            });
        },
        isDisabled() {
            return (
                this.auth().user_type == "user" || this.enable_edit_var == false
            );
        },
        isUserBooking() {
            return this.userSelectedServices.length > 0;
        },
        itemDisabled() {
            return (
                this.enable_edit_var == false ||
                this.draft.carrier_service_type != this.serviceInternational ||
                this.draft.carrier_code != "DHL" ||
                this.draft.type_of_good == this.goodsDocument
            );
        },
        getServiceCount() {
            let count = 0;

            this.availableCourierServices.forEach(service => {
                count += service.servicenames.length;
            });

            return count;
        }
    },
    data() {
        return {
            user_branch_name: '',
            hub_admin: false,
            hs_code_link: process.env.VUE_APP_HS_CODE_DHL || '#',
            handling_fee_percentage: '',
            price_without_GST: '',
            handling_fee: '',
            total_price: '',
            gst_value: '',
            total_price_with_gst: '',
            fuel_surcharge: '',
            holidayValidation: true,
            disable_gl_code: true,
            gl_code_placeholder: 'GL Code',
            updateType: false,
            show_approval_reference: false,
            findcourier_btn_edit: false,
            findcourier_btn: {
                length: false,
                disabled: false,
                value: "FIND COURIERS",
            },
            has_standard: 1,
            viewconsignmentpopup_booking_id: "",
            viewconsignmentpopup_booking_prefix: "",
            delivery_addresses_count: 0,
            max: 30,
            max1: 60,
            max2: 10,
            max3: 70,
            max4: 100,
            max5: 120,
            max6: 200,
            configDate: {
                format: "d-m-Y",
                altFormat: "d-m-Y",
                altInput: true,
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            changePickup: false,
            changeGlCode: false,
            showEditContact: false,
            courier_list: false,
            error_class: false,
            edit_mode: false,
            showAddressList: {
                all: [],
                success: [],
                failed: [],
                ongoing: [],
            },
            pickup_rate: {
                success: [],
                failed: [],
            },
            deliver_rate: {
                all: [],
                success: [],
                failed: [],
                ongoing: [],
            },
            addressHistoryList: false,
            enable_edit_var: false,
            package_details_var: false,
            parcel_var: true,
            volume: "",
            servicetypes: "",
            courier: "",
            ratecouriers: [],
            is_carrier_preference: "",
            threshold_identifier: "",
            final_weight_flag: "",
            count_weight: 0,
            draft: {
                custom_code: "",
                request_date: moment(new Date()).format("YYYY-MM-DD"),
                entry_type: "single",
                requester_name: "",
                branch_id: "",
                type_of_good: "",
                carrier_service_type: "",
                delivery_service_type: "",
                package_type: "",
                mode_of_dispatch: "",
                carrier_code: "",
                provider_id: "",
                service_id: "",
                p: "",
                t: "",
                a: "",
                e: "",
                o: "",
                has_priority: 0,
                has_emergency: 0,
                reference_no: "",
                temp_pickup_address_id: "",
                pickup_name: "",
                pickup_company: "",
                pickup_branch: "",
                pickup_floor: '',
                pickup_desk: '',
                pickup_address_1: "",
                pickup_address_2: "",
                pickup_postal_code: "",
                pickup_city: "",
                client: 'Contract',
                payment_obtained_in_pos: true,
                pickup_suburb: "",
                pickup_state: "",
                pickup_country: "",
                pickup_country_code: "",
                pickup_phone: "",
                pickup_mobile: "",
                pickup_email: "",
                delivery_name: "",
                delivery_company: "",
                delivery_branch: "",
                delivery_floor: '',
                delivery_desk: '',
                delivery_address_1: "",
                delivery_address_2: "",
                delivery_postal_code: "",
                delivery_city: "",
                delivery_suburb: "",
                delivery_state: "",
                delivery_country: "",
                delivery_country_code: "",
                delivery_phone: "",
                delivery_mobile: "",
                delivery_email: "",
                goods_description: "",
                pickup_instructions: "",
                remarks: "",
                delivery_address: [],
                booking_type: "Courier",
                priority: "Standard",
                sla: "",
                invoice_no: '',
                invoice_date: moment(new Date()).format("YYYY-MM-DD"),
                packages: [
                    {
                        type: 'Document',
                        unit_measurement: 'cm',
                        weight: '',
                        length: '',
                        width: '',
                        height: '',
                        m3: 0,
                        lineItems: []
                    }
                ],
                weight: "",
                pickup_date: moment().format("YYYY-MM-DD"),
                pickup_time: moment().format("HH:mm:ss"),
                office_close_time: "17:00:00",
                matter_number_id: '',
                gl_code: '',
                is_point_to_point: false,
                is_default_courier_messenger: false,
                pickup_address_category: 'internal',
                delivery_address_category: 'personal',
                id: '',
                user_selected_services: [],
                user_delivery_service_comment: '',
                is_courier_pickup_date_required: true,
                is_courier_pickup_time_required: true,
                payer_vat_number: '',
                recipient_reference: '',
                shipment_type: '',
                signature_name: '',
                signature_title: '',
                gross_weight: '',
                net_weight: '',
                is_authority_to_leave: 0,
                movement_type: 'despatch',
            },
            company_address_book: {
                pickup: false,
                delivery: false,
            },
            personal_address_book: {
                pickup: false,
                delivery: false,
            },
            provider_details: "",
            show_pickup_suggestion: false,
            show_destination_suggestion: false,
            show_requester_suggestion: false,
            pickupAddressCount: 0,
            selectedAddresses: [],
            managerName: [],
            branches: [],
            dispatches: [],
            goods: [],
            providers: [],
            services: [],
            address_list: [],
            countries: [],
            save_btn: {
                disabled: false,
                value: "SAVE AS DRAFT",
            },
            save_postal_btn: {
                disabled: false,
                value: "SAVE JOB",
            },
            submit_btn: {
                disabled: false,
                value: "Submit",
            },
            available_service_btn: {
                disabled: false,
                value: "Show Available Services",
            },
            dispatchsubmit_btn: {
                disabled: false,
                value: "Submit & Dispatch",
            },
            save_address: {
                internal: false,
                external: false,
            },
            today_pickup: true,
            matter_numbers: [{ id: '', label: '' }],
            temp_pickup_date: '',
            temp_pickup_time: '',
            window_pickup_from_time: '08:00:00',
            window_pickup_to_time: '17:00:00',
            lcountries: [],
            goodsDocument: process.env.VUE_APP_TYPE_OF_GOODS_DOCUMENT,
            goodsParcel: process.env.VUE_APP_TYPE_OF_GOODS_PARCEL,
            serviceInternational: process.env.VUE_APP_TYPE_OF_SERVICE_INTERNATIONAL,
            preparingForm: false,
            domesticCourierServices: [],
            InternationalCourierServices: [],
            userSelectedServices: [],
            isDomesticSelected: false,
            availableCourierServices: [],
            shipment_types: ["commercial", "personal"],
            export_reasons: [],
            commodity_type_codes: ["outbound", "inbound"],
            customer_ref_type_codes: [],
            unit_measurements: [],
            hide_ssit: false,
            userBranchAddress: {},
            allow_multiple_items: true,
            check_packages_limit: false,
            max_packages: 2,
            check_line_items_limit: false,
            max_line_items: 2,
            courier_integrator_code: ''
        };
    },

    async created() {

        this.$emit("breadCrumbData", ["Courier", "Courier Booking"]);

        if (this.draft.has_emergency == 0 && this.draft.has_priority == 0) {
            this.has_standard = 3;
        }

        this.preparingForm = true;

        await this.fetchUserData();
        await this.fetchAddresses();
        await this.fetchPackageType();
        await this.fetchMatterNumber();

        this.preparingForm = false;

        await this.fetchCarrierServiceType();
        await this.fetchExportReasonTypes();
        await this.fetchUnitMeasurements();
        await this.fetchCustomerRefTypeCodes();
        await this.fetchBranch();
        await this.fetchCountryCode();

        this.getCountries();

        if (this.$route.params.id == undefined) {
            this.enable_edit_var = true;
            this.parcel_var = true;
        }

        //EDIT
        if (this.$route.params.id != undefined) {

            this.edit_mode = true;
            this.enable_edit_var = true; //if this.enable_edit_var=false , we cannot type in the textbox.

            await this.populateDraft(this.$route.params.id);


        } else {

            try {
                if (!this.hub_admin) {
                    this.fetchGlCode();
                }
            }
            catch (error) {
                console.log(error);
            }
        }

        this.getRate('pickup')
    },
    methods: {
        ...mapActions({
            setCountries: 'country/setCountries',
            setAvailableCourierProviders: 'courierServices/setAvailableCourierProviders'
        }),
        viewAddressHistory(addressObject) {
            this.showAddressList = addressObject
            this.addressHistoryList = true
        },
        toggleServiceSelection(service) {

            if (this.draft.user_selected_services.includes(service)) {
                this.draft.user_selected_services = [];
                return;
            }

            this.draft.user_selected_services = [service];
        },
        rateCalculator(rateObject) {
            if (!rateObject.success) {
                return;
            }
            const total = rateObject.success.length + rateObject.failed.length

            if (total) {
                return parseFloat(parseFloat(rateObject.success.length / total).toFixed(2) * 100).toFixed(2)
            }

            return;
        },
        async getRate(type = null) {
            const url = "/api/address/rate"
            let address = {};
            if ('pickup' == type) {
                address.type = 'pickup'
                address.floor = this.draft.pickup_floor
                address.desk = this.draft.pickup_desk
                address.address_1 = this.draft.pickup_address_1
                address.address_2 = this.draft.pickup_address_2
                address.suburb = this.draft.pickup_suburb
                address.city = this.draft.pickup_city
                address.state = this.draft.pickup_state
                address.postal_code = this.draft.pickup_postal_code
                address.country = this.draft.pickup_country
                this.pickup_rate = {
                    sucess: [],
                    failed: [],
                }
            } else {
                address.type = 'delivery'
                address.floor = this.draft.delivery_floor
                address.desk = this.draft.delivery_desk
                address.address_1 = this.draft.delivery_address_1
                address.address_2 = this.draft.delivery_address_2
                address.suburb = this.draft.delivery_suburb
                address.city = this.draft.delivery_city
                address.state = this.draft.delivery_state
                address.postal_code = this.draft.delivery_postal_code
                address.country = this.draft.delivery_country

                this.deliver_rate = {
                    sucess: [],
                    failed: [],
                }
            }
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.post(url, address);
                const endTime = new Date().getTime();
                console.log('15 GetRate Response time:', endTime - startTime, 'ms');
                if (response.data) {
                    if ('pickup' == type) {
                        this.pickup_rate = response.data
                    } else {
                        this.deliver_rate = response.data
                    }
                }
            } catch (error) {
            }
        },
        async validateHoliday(pickupDate, pickupState) {
            let pickupData = {};

            pickupData.pickupDate = pickupDate;
            pickupData.pickupState = pickupState;

            try {
                await this.axios.post("/api/validate-date", pickupData)
                    .then((response) => {
                        this.holidayValidation = response.data.status;
                    })
                    .catch((error) => {
                        console.log("error", error.response);
                        this.toast.error();
                    });;

            } catch (error) {
            }
        },
        searchConsignment(booking) {
            this.viewconsignmentpopup_booking_prefix = booking.booking_prefix;
            this.viewconsignmentpopup_booking_id = booking.booking_id;
        },
        closeConsignment() {
            this.viewconsignmentpopup_booking_id = "";
            this.courier = this.courier.map(element => {
                element.show_view_popup = false;
                return element;
            });

            this.$router.push("/pages/ViewCourierPending");
        },
        fetchLocationApi(type) {
            let options = {
                types: ["geocode"],
            };
            let map = new google.maps.places.Autocomplete(this.$refs[type], {
                componentRestrictions: { country: [] },
                fields: ["address_components", "geometry"],
                types: ["address"],
            });
            console.log(map);
            map.addListener("place_changed", () => {
                let place = map.getPlace();

                this.draft.delivery_address_1 = this.draft.delivery_postal_code = "";

                console.log(JSON.stringify(place));
                for (const component of place.address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                        case "street_number": {
                            this.draft.delivery_address_1 =
                                component.short_name;
                            break;
                        }

                        case "route": {
                            this.draft.delivery_address_1 = `${this.draft.delivery_address_1
                                } ${component.long_name}`;
                            break;
                        }

                        case "postal_code": {
                            this.draft.delivery_postal_code =
                                component.long_name;
                            break;
                        }

                        case "postal_code_suffix": {
                            this.draft.postcode = `${this.draft.delivery_postal_code
                                }-${component.long_name}`;
                            break;
                        }
                        case "locality": {
                            this.draft.delivery_city = component.short_name;
                            this.draft.delivery_suburb = component.short_name;
                            break;
                        }

                        case "administrative_area_level_1": {
                            this.draft.delivery_state = component.short_name;
                            break;
                        }
                        case "country":
                            this.draft.delivery_country = component.long_name;
                            this.draft.delivery_country_code = component.short_name;
                            break;
                    }
                }

                this.draft.delivery_address_category = 'personal';
                this.getRate('delivery')
            });
        },
        addPackage() {

            if (this.check_packages_limit) {

                const limit = this.max_packages;

                if (this.draft.packages.length >= limit) {
                    this.toast.error(`Max package limit of ${limit} exceeded.`);

                    return;
                }
            }

            const { type = 'CTN', unit_measurement = 'cm' } = this.draft.packages[0] || {};

            var lineItems = [];

            if (this.draft.delivery_service_type === 'International') {

                const defaultValues = {
                    item_reference: "NA",
                    item_type: type,
                    item_description: type,
                    item_charge_unit: "cm",
                    item_qty: 1,
                    item_weight: "",
                    item_length: "",
                    item_width: "",
                    item_height: "",
                    item_m3: 0,
                    item_cubic_kg: 0,
                    item_is_dg: 0,
                    export_reason_type: "permanent",
                    commodity_type_code: null,
                    commodity_code_value: '',
                    customer_ref_type_code: 'AAJ',
                    customer_ref_type_value: '',
                    unit_measurement: 'BOX',
                    is_tax_paid: "false",
                    product_code: "",
                    country_code: "PH",
                    commercial_value: "",
                    insured_value: "",
                    currency: "PHP",
                    booking_id: "",
                    id: "",
                    carrier_code: this.draft.carrier_code,
                };

                lineItems.push(defaultValues);
            }

            this.draft.packages.push({
                type,
                unit_measurement,
                weight: '',
                length: '',
                width: '',
                height: '',
                m3: 0,
                lineItems: lineItems
            });
        },
        removePackage(id) {
            if (confirm('Are you certain you want to remove this package?')) {
                this.draft.packages.splice(id, 1);
            }
        },
        addLineItems(p) {

            if (this.check_line_items_limit) {

                const limit = this.max_line_items;

                if (this.draft.packages[p].lineItems.length >= limit) {
                    this.toast.error(`Max items limit of ${limit} exceeded.`);

                    return;
                }
            }

            const pkg = this.draft.packages[p];

            const defaultValues = {
                item_reference: "NA",
                item_type: pkg.type,
                item_description: pkg.type,
                item_charge_unit: "cm",
                item_qty: "",
                item_weight: "",
                item_length: "",
                item_width: "",
                item_height: "",
                item_m3: 0,
                item_cubic_kg: 0,
                item_is_dg: 0,
                export_reason_type: "permanent",
                commodity_type_code: null,
                commodity_code_value: '',
                customer_ref_type_code: 'AAJ',
                customer_ref_type_value: '',
                unit_measurement: 'BOX',
                is_tax_paid: "false",
                product_code: "",
                country_code: "PH",
                commercial_value: "",
                insured_value: "",
                currency: "PHP",
                booking_id: "",
                id: "",
                carrier_code: this.draft.carrier_code,
            };

            pkg.lineItems.push(defaultValues);

        },
        removePackageItem(p, i) {

            if (confirm('Are you certain you want to remove this item?')) {
                this.draft.packages[p].lineItems.splice(i, 1);
            }
        },
        updatePackageItemM3(p, i) {

            const m3 = ((this.draft.packages[p].lineItems[i].item_length * this.draft.packages[p].lineItems[i].item_width * this.draft.packages[p].lineItems[i].item_height) / 1000000);

            this.draft.packages[p].lineItems[i].item_m3 = m3;
            this.draft.packages[p].lineItems[i].item_cubic_kg = m3;
        },
        updatePackageM3(p) {

            const pkg = this.draft.packages[p];

            if (pkg) {
                const m3 = ((pkg.length * pkg.width * pkg.height) / 1000000);

                this.draft.packages[p].m3 = m3;
            }
        },
        updateTotalWeight() {

            var total_weight = 0;

            this.draft.packages.forEach((pkg, pIndex) => {

                total_weight += Number(pkg.weight);
            });

            this.draft.weight = total_weight;
            this.draft.gross_weight = total_weight;
            this.draft.net_weight = total_weight;

        },
        customerRefenceNo() {
            var refference_no = "";

            if (
                this.draft.carrier_service_type == this.serviceInternational &&
                this.draft.carrier_code == "DHL" &&
                this.draft.type_of_good != this.goodsDocument
            ) {
                if (this.draft.reference_no)
                    refference_no = this.draft.reference_no;
                else refference_no = this.draft.pickup_company;

                var els = document.getElementsByClassName("invoice_number");
                for (var i = 0; i < els.length; i++) {
                    els[i].value = refference_no;
                }

                let j = 0;
                this.draft.courierItem.forEach(() => {
                    this.draft.courierItem[j].invoice_no = refference_no;
                    j++;
                });
            }
        },
        hideSuggestions() {
            setTimeout(() => {
                this.show_pickup_suggestion = false;
                this.show_destination_suggestion = false;
            }, 200);
        },
        hideRequesterSuggestions() {

            setTimeout(() => {
                this.show_requester_suggestion = false;
            }, 300);
        },
        setReqesterName(address) {
            this.draft.requester_name = address.name;

            this.show_requester_suggestion = false;
        },
        async fetchCountryCode() {
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/common/countries');
                const endTime = new Date().getTime();
                console.log('11 FetchCountryCode Response time:', endTime - startTime, 'ms');
                this.countries = response.data.countries;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchBranch() {
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/branch');
                const endTime = new Date().getTime();
                console.log('10 FetchBranch Response time:', endTime - startTime, 'ms');
                this.branches = response.data.branches;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchPackageType() {
            try {
                this.goods = [];

                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/goods/quotebook?type=outbound');
                const goods = response.data.goods;

                const endTime = new Date().getTime();
                console.log('2 FetchPackageType Response time:', endTime - startTime, 'ms');

                this.goodsTypes = ['Document', 'Parcel', 'Credit Card', 'Debit Card', 'Statements', 'Debit/Credit Pins', 'Security Device/Token'];

                if ('Postal' == this.draft.delivery_service_type) {
                    this.goods = goods;
                } else {
                    goods.forEach((good) => {
                        if (this.goodsTypes.includes(good.type)) {
                            this.goods.push(good);
                        }
                    });
                }

                if (this.goods.length > 0) {
                    if (this.$route.params.id == undefined) {
                        this.draft.type_of_good = this.goodsDocument
                    }

                    this.goods_type = this.goods[0].type;

                    if (this.$route.params.id == undefined) {
                        this.goods_weight = this.goods[0].weight;
                        this.goods_length = this.goods[0].length;
                        this.goods_width = this.goods[0].width;
                        this.goods_height = this.goods[0].height;
                        this.goods_m3 = ((this.goods[0].height * this.goods[0].length * this.goods[0].width) / 100).toFixed(3);
                        this.goods_is_dg = this.goods[0].is_dg;
                        this.goods_cubic_kg = this.goods[0].cubic_kg;
                    }
                }

                if (this.draft.type_of_good) {
                    await this.setPackageType(this.draft.type_of_good);
                }

                await this.fetchDispatch();
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchCarrierServiceType() {
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/common/servicetype');
                const endTime = new Date().getTime();
                console.log('5 FetchCarrierServiceType Response time:', endTime - startTime, 'ms');

                this.servicetypes = response.data.servicetype;

                if (this.auth().user_type == 'user') {
                    const serviceTypes = this.servicetypes.filter((type) => type.service_type == 'Messenger');
                    this.servicetypes = serviceTypes;
                    this.draft.carrier_service_type = this.servicetypes[0].id;
                    return;
                }

                let messengerType = null
                this.servicetypes.map(function (v) {
                    if ('Messenger' == v.service_type) {
                        messengerType = v.id
                        return false
                    }
                })

                if (this.$route.params.id == undefined) {
                    if (this.servicetypes.length > 0) {
                        this.draft.carrier_service_type = messengerType ? messengerType : this.servicetypes[0].id;
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchExportReasonTypes() {
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/common/export-reason-types');
                const endTime = new Date().getTime();
                console.log('6 FetchExportReasonTypes Response time:', endTime - startTime, 'ms');

                this.export_reasons = response.data.types;

            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchUnitMeasurements() {
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/common/unit-measurements');
                const endTime = new Date().getTime();
                console.log('7 FetchUnitMeasurements Response time:', endTime - startTime, 'ms');
                this.unit_measurements = response.data.measurements;

            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchCustomerRefTypeCodes() {
            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get('/api/common/customer-ref-type-codes');
                const endTime = new Date().getTime();
                console.log('8 FetchCustomerRefTypeCodes Response time:', endTime - startTime, 'ms');
                this.customer_ref_type_codes = response.data.codes;
            }
            catch (error) {
                console.log(error);
            }
        },
        async fetchDispatch() {
            //open package details section when type of good is parcel
            if (this.draft.type_of_good == this.goodsParcel) {
                this.parcel_var = true;
                this.package_details_var = true;
            } else {
                this.parcel_var = false;
                this.package_details_var = false;
            }
            if (this.draft.type_of_good) {
                try {
                    const startTime = new Date().getTime();
                    const response = await this.axios.get(`/api/goods/${this.draft.type_of_good}`);
                    const endTime = new Date().getTime();
                    console.log('3 FetchDispatch Response time:', endTime - startTime, 'ms');
                    let i = 0;
                    if (this.$route.params.id == undefined) {

                        const m3 = ((response.data.goods.length * response.data.goods.width * response.data.goods.height) / 1000000);

                        const goods_type = response.data.goods.type;
                        const is_dg = response.data.goods.is_dg;

                        this.draft.packages.forEach((pkg, pIndex) => {
                            pkg.type = goods_type;

                            if (Array.isArray(pkg.lineItems) && pkg.lineItems.length > 0) {
                                pkg.lineItems.forEach((lineItem, iIndex) => {
                                    lineItem.item_type = goods_type;
                                    lineItem.item_is_dg = is_dg;
                                    lineItem.item_cubic_kg = m3;
                                    if (this.$route.params.id == undefined) {
                                        lineItem.item_description = (this.draft.delivery_service_type === 'International' && this.draft.package_type === 'Parcel')
                                            ? ""
                                            : goods_type;
                                    }
                                });
                            }
                        });

                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        },
        viewCompanyAddressBook(type) {
            Object.keys(this.company_address_book).map((element) => {
                this.company_address_book[element] = false;
                this.personal_address_book[element] = false;
            });
            this.company_address_book[type] = true;
            this.personal_address_book[type] = false;
        },
        viewPersonalAddressBook(type) {
            Object.keys(this.personal_address_book).map((element) => {
                this.personal_address_book[element] = false;
                this.company_address_book[element] = false;
            });
            this.personal_address_book[type] = true;
            this.company_address_book[type] = false;
        },
        viewPriceBreakdownPopup(courier) {
            this.handling_fee_percentage = courier.handling_fee_percentage;
            this.price_without_GST = courier.price_without_GST;
            this.handling_fee = courier.handling_fee;
            this.total_price = courier.total_price;
            this.gst_value = courier.gst_value;
            this.total_price_with_gst = courier.total_price_with_gst;
            this.fuel_surcharge = courier.fuel_surcharge;
        },
        async fetchUserData() {
            this.loading = true;

            try {
                const startTime = new Date().getTime();
                const response = await this.axios.get(`/api/user/data`);

                const endTime = new Date().getTime();
                console.log('13 FetchUserData Response time:', endTime - startTime, 'ms');


                this.userBranchAddress = {
                    floor: response.data.msg.branch.floor,
                    desk: response.data.msg.branch.desk,
                    address_1: response.data.msg.branch.address_1,
                    address_2: response.data.msg.branch.address_2,
                    postal_code: response.data.msg.branch.postal_code,
                    city: response.data.msg.branch.city,
                    suburb: response.data.msg.branch.suburb,
                    state: response.data.msg.branch.state,
                    country: response.data.msg.branch.country,
                };

                if (this.$route.params.id != undefined) {
                    return;
                }

                this.hub_admin = response.data.msg.is_admin == 1;
                this.draft.pickup_name = response.data.msg.name;
                this.draft.pickup_branch = response.data.msg.branch ? response.data.msg.branch.name : "";
                this.draft.pickup_floor = response.data.msg.floor;
                this.draft.pickup_desk = response.data.msg.desk;
                this.draft.pickup_address_1 = response.data.msg.address_1;
                this.draft.pickup_address_2 = response.data.msg.address_2;
                this.draft.pickup_postal_code = response.data.msg.postal_code;
                this.draft.pickup_city = response.data.msg.city;
                this.draft.pickup_suburb = response.data.msg.suburb;
                this.draft.pickup_state = response.data.msg.state;
                this.draft.pickup_country = response.data.msg.country;
                this.draft.pickup_phone = response.data.msg.phone;
                this.draft.pickup_mobile = this.draft.serviceType == this.serviceInternational && this.draft.carrier_code == 'DHL'
                    ? response.data.msg.phone
                    : response.data.msg.mobile;
                this.draft.pickup_email = response.data.msg.email;
                this.draft.pickup_company = response.data.msg.company.name;

                this.draft.requester_name = response.data.msg.name;
                this.draft.signature_name = response.data.msg.name;
                this.draft.signature_title = this.transformString(this.auth().user_type);
                this.draft.branch_id = response.data.msg.branch.id;
                this.show_pickup_suggestion = false;

                if (response.data.msg.division.configs.length > 0) {
                    response.data.msg.division.configs.forEach((config) => {
                        if (config.key == 'is_point_to_point') {
                            this.draft.is_point_to_point = config.value == 'Y' ? 1 : 0;
                        }
                        if (config.key == 'is_default_courier_messenger') {
                            this.draft.is_default_courier_messenger = config.value == 'Y' ? 1 : 0;
                        }
                    });
                }
                // this.loading = false;
            }
            catch (error) {
                console.log(error);
            }
        },
        populatePickupAddress(value) {
            if (typeof value.type !== 'undefined') {
                if (value.type != null) {
                    this.draft.address_type = value.type;
                }
                if (typeof value.category !== 'undefined') {
                    this.draft.pickup_address_category = value.category;
                }
            }

            if (!this.draft.pickup_country_code || value.from_address_book) {
                this.draft.pickup_address_1 = value.address_1;
                this.draft.pickup_address_2 = value.address_2;
                this.draft.pickup_postal_code = value.postal_code;
                this.draft.pickup_city = value.city;
                this.draft.pickup_suburb = value.suburb;
                this.draft.pickup_state = value.state;
                this.draft.pickup_country = value.country;
            }

            this.draft.temp_pickup_address_id = value.id;
            this.draft.pickup_name = value.name;
            this.draft.pickup_branch = value.branch_name;
            this.draft.pickup_company = value.company_name;
            this.draft.pickup_floor = value.floor;
            this.draft.pickup_desk = value.desk;
            this.draft.pickup_phone = value.phone;
            this.draft.pickup_mobile = value.mobile;
            // this.draft.pickup_email = value.email;
            if (this.auth().user_type != 'user' && !this.draft.carrier_service_type) {
                if (this.draft.pickup_address_category == 'external' && this.draft.delivery_address_category == 'external') {
                    this.draft.carrier_service_type = this.servicetypes[1].id;
                } else {
                    this.draft.carrier_service_type = this.servicetypes[0].id;
                }
            }

            this.isExternalPickup();

            this.getRate('pickup')
        },
        populateDeliveryAddress(addresses) {
            if (this.draft.entry_type == "single") {
                addresses = [addresses];
            }
            let selectedAddresses = addresses[0];

            if (typeof selectedAddresses.category !== 'undefined') {
                if (selectedAddresses.category != null) {
                    this.draft.delivery_address_category = selectedAddresses.category;
                }
                else if (typeof selectedAddresses.category !== 'undefined') {
                    this.draft.delivery_address_category = selectedAddresses.category;
                }
            }

            this.draft.delivery_name = selectedAddresses.name;
            this.draft.delivery_company = selectedAddresses.company_name;
            this.draft.delivery_branch = selectedAddresses.branch_name;
            this.draft.delivery_department = selectedAddresses.department
                ? selectedAddresses.department.department_name
                : "";

            if (!this.draft.delivery_country_code || selectedAddresses.from_address_book) {
                this.draft.delivery_floor = selectedAddresses.floor;
                this.draft.delivery_desk = selectedAddresses.desk;
                this.draft.delivery_address_1 = selectedAddresses.address_1;
                this.draft.delivery_address_2 = selectedAddresses.address_2;
                this.draft.delivery_postal_code = selectedAddresses.postal_code;
                this.draft.delivery_city = selectedAddresses.city;
                this.draft.delivery_suburb = selectedAddresses.suburb;
                this.draft.delivery_state = selectedAddresses.state;
                this.draft.delivery_country = selectedAddresses.country;
                this.draft.delivery_phone = selectedAddresses.phone;
                this.draft.delivery_mobile = selectedAddresses.mobile;
                this.draft.delivery_email = selectedAddresses.email;
                this.draft.address_book_id = selectedAddresses.id;

                let countries = localStorage.getItem('countries');
                countries = JSON.parse(countries);

                let selectedCountry = countries.find((country) => {
                    return country.name == selectedAddresses.country;
                });
                this.draft.delivery_country_code = selectedCountry.code;

                this.mapDeliveryAddresses(addresses);
                this.delivery_addresses_count = this.draft.delivery_address.length;
                this.show_destination_suggestion = false;
            }

            if (this.auth().user_type != 'user' && !this.draft.carrier_service_type) {
                if (this.draft.pickup_address_category == 'external' && this.draft.delivery_address_category == 'external') {
                    this.draft.carrier_service_type = this.servicetypes[1].id;
                } else {
                    this.draft.carrier_service_type = this.servicetypes[0].id;
                }
            }

            this.getRate('delivery')
        },
        mapDeliveryAddresses(addresses, selected_from_address_book = true) {
            let i = 0;
            this.draft.delivery_address = [];
            addresses.forEach((address) => {
                this.draft.delivery_address[i] = {
                    delivery_name: address.name,
                    delivery_company: address.company_name,
                    delivery_branch: address.branch_name,
                    delivery_department: address.department
                        ? address.department.department_name
                        : "",
                    delivery_floor: address.floor,
                    delivery_desk: address.desk,
                    delivery_address_1: address.address_1,
                    delivery_address_2: address.address_2,
                    delivery_postal_code: address.postal_code,
                    delivery_city: address.city,
                    delivery_suburb: address.suburb,
                    delivery_state: address.state,
                    delivery_country: address.country,
                    delivery_phone: address.phone,
                    delivery_mobile: address.mobile,
                    delivery_email: address.email,
                    address_book_id: selected_from_address_book
                        ? address.id
                        : address.address_book_id
                            ? address.address_book_id
                            : address.id,
                };
                i++;
            });
        },
        async fetchAddresses() {
            const startTime = new Date().getTime();
            try {
                const response = await this.axios.post('api/address/list');
                this.address_list = response.data.msg;
            }
            catch (error) {
                console.log(error);
            }
            const endTime = new Date().getTime();
            console.log('1 FetchAddresses Response time:', endTime - startTime, 'ms');
        },
        closeModal(value) {
            this.company_address_book[value.type] = false;
            this.personal_address_book[value.type] = false;
        },
        ClosePriceBreakdownPopupModal() {
            this.handling_fee_percentage = "";
            this.price_without_GST = "";
            this.handling_fee = "";
            this.total_price = "";
            this.gst_value = "";
            this.total_price_with_gst = "";
            this.fuel_surcharge = "";
            this.$router.push("/pages/QuoteAndBook");
        },
        populateManagerName(manager) {
            this.draft.manager_name = manager;
            if (
                this.draft.manager_name != "" &&
                this.threshold_identifier == 1
            ) {
                this.submit();
            } else if (
                this.draft.manager_name != "" &&
                this.threshold_identifier == 2
            ) {
                this.dispatchsubmit();
            } else {
                this.toast.error("Approval Reference Required for Submission ");
            }
        },
        closeModalApprovalReference() {
            this.show_approval_reference = false;
        },
        populatePickupAddressApi(pickupaddress) {
            console.log(pickupaddress);
            this.draft.pickup_floor = pickupaddress.pickup_floor;
            this.draft.pickup_desk = pickupaddress.pickup_desk;
            this.draft.pickup_address_1 = pickupaddress.pickup_address_1;
            this.draft.pickup_address_2 = pickupaddress.pickup_address_2;
            this.draft.pickup_suburb = pickupaddress.pickup_suburb;
            this.draft.pickup_city = pickupaddress.pickup_city;
            this.draft.pickup_state = pickupaddress.pickup_state;
            this.draft.pickup_postal_code = pickupaddress.pickup_postal_code;
            this.draft.pickup_country = pickupaddress.pickup_country;
            this.draft.pickup_country_code = pickupaddress.pickup_country_code;
            this.draft.pickup_address_category = 'personal';

            this.isExternalPickup();
            this.getRate('pickup')
        },
        populateGlCodeApi(glCode) {
            if (glCode !== undefined) {
                this.draft.gl_code = glCode;
                this.disable_gl_code = true;
                this.gl_code_placeholder = 'GL Code';
                this.draft.custom_code = 'Gl Code';
                $("#radio_pal").prop('checked', false);
                $("#radio_other").prop('checked', false);
            }
        },
        //send details to getRateApi
        async findCouriers() {

            this.ratecouriers = [];

            if (this.auth().user_type != 'user') {

                const packages_invalid = this.validatePackageData();

                if (packages_invalid) {
                    return;
                }
            }

            this.courier_list = false;
            this.error_class = false;
            this.findcourier_btn_edit = true;

            this.courier = {
                requester_name: this.draft.requester_name,
                branch_id: this.draft.branch_id,
                request_date: this.draft.request_date,
                type_of_good: this.draft.type_of_good,
                mode_of_dispatch_name: "Courier",
                mode_of_dispatch: "b1e07d18918a433096936e76cdba63e4",
                carrier_service_type: this.draft.carrier_service_type,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_floor: this.draft.delivery_floor,
                delivery_desk: this.draft.delivery_desk,
                pickup_address_1: this.draft.pickup_address_1,
                pickup_city: this.draft.pickup_city,
                pickup_postal_code: this.draft.pickup_postal_code,
                pickup_state: this.draft.pickup_state,
                pickup_country: this.draft.pickup_country,
                pickup_country_code: this.draft.pickup_country_code,
                pickup_floor: this.draft.pickup_floor,
                pickup_desk: this.draft.pickup_desk,
                priority: this.draft.priority,
                pickup_date: this.draft.pickup_date,
                pickup_time: this.draft.pickup_time,
                client_type: this.draft.client,
                packages: this.draft.packages
            };

            this.findcourier_btn.disabled = true;
            this.findcourier_btn.length = false;
            this.findcourier_btn.value = "PLEASE WAIT..";

            await this.axios
                .post("/api/courier/findCouriers-new", this.courier)
                .then((response) => {
                    this.toast.success(response.data.msg);
                    this.draft.mode_of_dispatch = response.data.couriers[0].mode_of_dispatch;
                    this.ratecouriers = response.data.couriers.map(
                        (element) => {
                            element.is_checked = false;

                            if (!element.rate) element.rate = 0;
                            element.cost = element.rate.toFixed(2);

                            if (element.service_code == "DBL") {
                                element.courier_rate = 0;
                                element.cost = (element.markup_margin_type ==
                                    "per"
                                    ? element.rate + (element.markup_margin * 1 * element.rate) / 100
                                    : element.rate + element.markup_margin * 1
                                ).toFixed(2);
                            }

                            if (element.transit_type == "country") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_country.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_country.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "state") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_state.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_state.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "city") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_city.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_city.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "postcodes") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_postal_code.toUpperCase() && element.to_destination.toUpperCase() == this.draft.delivery_postal_code.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else if (element.transit_type == "suburbs") {
                                element.transit_time = element.transit_days > 0
                                    ? element.from_origin.toUpperCase() == this.draft.pickup_suburb.toUpperCase() &&
                                        element.to_destination.toUpperCase() == this.draft.delivery_suburb.toUpperCase()
                                        ? element.transit_days
                                        : element.default_transit_days
                                    : element.default_transit_days;
                            }
                            else {
                                element.transit_time = element.default_transit_days;
                            }

                            return element;
                        }
                    );

                    if (this.ratecouriers.length > 0) {
                        this.courier_list = true;
                        this.ratecouriers[0].is_checked = true;
                        this.findcourier_btn.disabled = false;
                        this.findcourier_btn.value = "FIND COURIERS";
                        this.SelectCourier(this.ratecouriers[0]);
                    } else {
                        this.findcourier_btn.length = true;
                    }

                    this.findcourier_btn.disabled = false;
                    this.findcourier_btn.value = "FIND COURIERS";

                    if (response.data.couriers[0].window_schedule) {
                        this.window_pickup_from_time = response.data.couriers[0].window_schedule.from;
                        this.window_pickup_to_time = response.data.couriers[0].window_schedule.to;
                    }
                })
                .catch((e) => {
                    this.findcourier_btn.disabled = false;
                    this.findcourier_btn.length = true;
                    this.findcourier_btn.value = "FIND COURIERS";
                    console.error(e);
                });
        },
        SelectCourier(ratecourier) {

            this.draft.provider_id = ratecourier.provider_id;

            this.draft.cost = ratecourier.cost;
            
            this.draft.service_id = ratecourier.service_id;
            this.draft.carrier_code = ratecourier.carrier_code;
            this.draft.transit_time = ratecourier.transit_time;
            this.draft.carrier_code = ratecourier.carrier_code;
            this.draft.courier_rate = ratecourier.courier_rate;

            if (ratecourier.carrier_code == 'STC' && ratecourier.service_code == 'FPP') {
                this.allow_multiple_items = false;
            } else {
                this.allow_multiple_items = true;
            }

            this.draft.handling_fee = ratecourier.handling_fee;
            this.draft.total_price_with_gst = ratecourier.total_price_with_gst;
            this.draft.fuel_surcharge = ratecourier.fuel_surcharge;
            this.draft.price_without_GST = ratecourier.price_without_GST;
            this.draft.gst_value = ratecourier.gst_value;
            this.draft.total_price = ratecourier.total_price;
            this.draft.handling_fee_percentage = ratecourier.handling_fee_percentage;

            this.courier_integrator_code = ratecourier.integrator_code || this.stc_get_rate_account;

            if (this.draft.carrier_code == "DHL" && this.$route.params.id == undefined) {

                if (this.draft.packages.length > 0) {

                    for (const [p, pkg] of this.draft.packages.entries()) {
                        if (pkg.lineItems.length > 0) {
                            for (const [i, item] of pkg.lineItems.entries()) {
                                item.carrier_code = this.draft.carrier_code;
                            }
                        }
                    }

                }
            }

            if (ratecourier.window_schedule) {
                this.window_pickup_from_time = ratecourier.window_schedule.from;
                this.window_pickup_to_time = ratecourier.window_schedule.to;
            }

            const mandatoryDimensionsCouriers = ['STC', 'DHL'];
            this.package_details_var = mandatoryDimensionsCouriers.includes(ratecourier.carrier_code);

            this.draft.pickup_mobile = this.draft.carrier_service_type == this.serviceInternational && this.draft.carrier_code == 'DHL'
                ? this.draft.pickup_phone
                : this.draft.pickup_mobile;

            this.draft.delivery_mobile = this.draft.carrier_service_type == this.serviceInternational && this.draft.carrier_code == 'DHL'
                ? this.draft.delivery_phone
                : this.draft.delivery_mobile;
        },
        async thresholdcheckdipatchsubmit() {

            // let errorOccurred = false;

            if (this.auth().user_type != 'user') {
                const packages_invalid = this.validatePackageData();

                if (packages_invalid) {
                    return;
                }
            }

            if (this.allow_multiple_items == false && this.draft.packages.length > 1) {
                this.toast.error(`Multiple packages are not allowed for this service`);
                return;
            }

            //--------Because For normal user find courier button is hidden-----
            if (this.auth().user_type == 'user') {
                await this.findCouriers();
            }
            //--------End-------

            this.loading = true;
            this.threshold_identifier = 2;

            if (this.auth().user_type == 'user' && this.draft.user_selected_services.length === 0) {
                this.toast.error("Please select service from the list.");
                return
            }

            if (this.draft.service_id == null || this.draft.provider_id == null) {
                this.toast.error("Please Press 'Find Couriers' and Choose a Carrier to Proceed");
            } else {

                if (!this.validatePickupDateTime()) {

                    this.toast.error(!this.today_pickup ? `Pickup Date and Time must be in future and inside window time of ${this.convertToAmPm(this.window_pickup_from_time)} to ${this.convertToAmPm(this.window_pickup_to_time)}` : `Pickup date must be today and time must be in inside window time of ${this.convertToAmPm(this.window_pickup_from_time)} to ${this.convertToAmPm(this.window_pickup_to_time)}`);

                    return;
                }

                /**
                 * Temporary validations for international (DHL) booking. It can be improved.
                 * Also, these are already validated thru backend/api.
                 */
                if (this.draft.carrier_code == 'DHL') {

                    if (this.draft.pickup_address_2 == '' || this.draft.pickup_address_2 == null) {
                        this.toast.error(`Please provide pickup address 2.`);
                        return;
                    }
                    else if (this.draft.pickup_mobile == '' || this.draft.pickup_mobile == null) {
                        this.toast.error(`Please provide pickup mobile number.`);
                        return;
                    }
                    if (this.draft.delivery_email == '' || this.draft.delivery_email == null) {
                        this.toast.error(`Please provide delivery email address.`);
                        return;
                    }
                    else if (this.draft.delivery_address_2 == '' || this.draft.delivery_address_2 == null) {
                        this.toast.error(`Please provide delivery address 2.`);
                        return;
                    }
                    else if (this.draft.delivery_phone == '' || this.draft.delivery_phone == null) {
                        this.toast.error(`Please provide delivery phone number.`);
                        return;
                    }
                    else if (this.draft.delivery_mobile == '' || this.draft.delivery_mobile == null) {
                        this.toast.error(`Please provide delivery mobile number.`);
                        return;
                    }
                }


                if (this.draft.goods_description && this.draft.goods_description != '') {

                    const regex = /^[^<>;]+$/;

                    if (!regex.test(this.draft.goods_description)) {
                        this.toast.error('The goods description format invalid.');

                        return;
                    }
                }


                try {
                    const response = await this.axios.get(`/api/budget/${this.draft.provider_id}/${this.draft.service_id}`);
                    if (response.data.data) {
                        if (this.draft.cost > response.data.data.approval_required_threshold * 1) {
                            // code for popup
                            this.show_approval_reference = true;
                        } else {
                            if (this.findcourier_btn_edit == true) {
                                this.dispatchsubmit();
                            } else {
                                this.toast.error(`Please Press 'Find Couriers' and Choose a Carrier to Proceed`);
                            }
                        }
                    } else {
                        //in case of threshold value not set
                        //Either we can directly submit or we can stop the procees here i am directly submitting

                        if (this.findcourier_btn_edit == true) {
                            this.dispatchsubmit();
                        } else {
                            this.toast.error(
                                "Please Press 'Find Couriers' and Choose a Carrier to Proceed"
                            );
                        }
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }
        },
        async submit() {

            this.draft.delivery_address[0] = {
                delivery_name: this.draft.delivery_name,
                delivery_company: this.draft.delivery_company,
                delivery_branch: this.draft.delivery_branch,
                delivery_department: this.draft.delivery_department,
                delivery_floor: this.draft.delivery_floor,
                delivery_desk: this.draft.delivery_desk,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_address_2: this.draft.delivery_address_2,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_phone: this.draft.delivery_phone,
                delivery_mobile: this.draft.delivery_mobile,
                delivery_email: this.draft.delivery_email,
                address_book_id: this.draft.address_book_id ? this.draft.address_book_id : "htfjfgjhgjg",
            };

            this.$refs.observer.validate();
            this.draft.is_draft = 0;

            this.submit_btn.disabled = true;
            this.submit_btn.value = "Submitting, Please wait";

            await this.saveToAddressBook();

            if (this.$route.params.id || this.draft.id) {

                const id = this.draft.id ? this.draft.id : this.$route.params.id;

                try {
                    const response = await this.axios.post(`/api/draft/quotebook/${id}`, this.draft);

                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(response.data.draft.id);
                        this.toast.error(response.data.msg);
                        return;
                    }

                    this.toast.success(response.data.msg);

                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";
                }

            } else {
                try {
                    const response = await this.axios.post(`/api/draft/quotebook`, this.draft);

                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(response.data.draft.id);
                        this.toast.error(response.data.msg);
                        return;
                    }


                    this.toast.success(response.data.msg);
                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.submit_btn.disabled = false;
                    this.submit_btn.value = "Submit";
                }
            }
        },
        async savePostalQuote() {

            if (this.save_postal_btn.disable) {
                this.toast.success("SAVING...");
                return;
            }

            this.save_postal_btn.disable = true
            this.save_postal_btn.value = 'SAVING...'

            this.draft.is_draft = 0;
            this.draft.booking_type = 'Postal'

            this.draft.payer_vat_number = null;
            this.draft.recipient_reference = null;
            this.draft.shipment_type = null;
            this.draft.signature_name = null;
            this.signature_title = null;
            this.draft.gross_weight = null;
            this.draft.net_weight = null;

            if (this.draft.packages.length > 0) {

                for (const [p, pkg] of this.draft.packages.entries()) {
                    if (pkg.lineItems.length > 0) {
                        for (const [i, item] of pkg.lineItems.entries()) {
                            item.commodity_type_code = null;
                            item.commodity_code_value = null;
                            item.customer_ref_type_code = null;
                            item.customer_ref_type_value = null;
                            item.unit_measurement = null;
                            item.is_tax_paid = null;
                        }
                    }
                }

            }

            this.axios
                .post("api/draft/quotebook/dispatch-new", this.draft)
                .then((response) => {

                    this.toast.success(response.data.msg);
                    this.save_postal_btn = {
                        disabled: false,
                        value: "Save",
                    }
                    this.$router.push("/pages/ViewCourierPending");
                })
                .catch((error) => {
                    console.log(error);
                    this.save_postal_btn = {
                        disabled: false,
                        value: "Save",
                    }
                });
        },
        async dispatchsubmit() {
            this.draft.delivery_address[0] = {
                delivery_name: this.draft.delivery_name,
                delivery_company: this.draft.delivery_company,
                delivery_branch: this.draft.delivery_branch,
                delivery_department: this.draft.delivery_department,
                delivery_floor: this.draft.delivery_floor,
                delivery_desk: this.draft.delivery_desk,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_address_2: this.draft.delivery_address_2,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_phone: this.draft.delivery_phone,
                delivery_mobile: this.draft.delivery_mobile,
                delivery_email: this.draft.delivery_email,
                address_book_id: this.draft.address_book_id
                    ? this.draft.address_book_id
                    : "",
            };

            this.$refs.observer.validate();
            this.draft.is_draft = 0;
            this.dispatchsubmit_btn.disabled = true;
            this.dispatchsubmit_btn.value = "Submitting, Please wait";

            await this.saveToAddressBook();

            if (this.$route.params.id || this.draft.id) {

                const id = this.draft.id ? this.draft.id : this.$route.params.id;

                try {
                    const response = await this.axios.post(`/api/draft/quotebook/dispatch-new/${id}`, this.draft);

                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(id);
                        this.toast.error(response.data.msg);
                        if (response.data.draft.id) {
                            this.$router.push("/pages/ViewCourierPending");
                        }
                        return;
                    }

                    this.toast.success(response.data.msg);
                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";
                }

            } else {

                try {
                    const response = await this.axios.post(`/api/draft/quotebook/dispatch-new`, this.draft);

                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";

                    if (response.data.status == 'error') {
                        this.edit_mode = true;
                        this.enable_edit_var = true;
                        await this.populateDraft(response.data.draft.id);
                        this.toast.error(response.data.msg);
                        if (response.data.draft.id) {
                            this.$router.push("/pages/ViewCourierPending");
                        }
                        return;
                    }

                    this.toast.success(response.data.msg);

                    if (this.auth().user_type == "user") {
                        this.searchConsignment(response.data)
                        return
                    }
                    this.$router.push("/pages/ViewCourierPending");
                }
                catch (error) {
                    this.dispatchsubmit_btn.disabled = false;
                    this.dispatchsubmit_btn.value = "Submit & Dispatch";
                }

            }
        },
        //issue
        async draftsubmit() {
            this.draft.delivery_address[0] = {
                delivery_name: this.draft.delivery_name,
                delivery_company: this.draft.delivery_company,
                delivery_branch: this.draft.delivery_branch,
                delivery_department: this.draft.delivery_department,
                delivery_floor: this.draft.delivery_floor,
                delivery_desk: this.draft.delivery_desk,
                delivery_address_1: this.draft.delivery_address_1,
                delivery_address_2: this.draft.delivery_address_2,
                delivery_postal_code: this.draft.delivery_postal_code,
                delivery_city: this.draft.delivery_city,
                delivery_suburb: this.draft.delivery_suburb,
                delivery_state: this.draft.delivery_state,
                delivery_country: this.draft.delivery_country,
                delivery_country_code: this.draft.delivery_country_code,
                delivery_phone: this.draft.delivery_phone,
                delivery_mobile: this.draft.delivery_mobile,
                delivery_email: this.draft.delivery_email,
                address_book_id: this.draft.address_book_id
                    ? this.draft.address_book_id
                    : "htfjfgjhgjg",
            };

            this.$refs.observer.validate();
            this.draft.is_draft = 1;

            this.save_btn.disabled = true;
            this.save_btn.value = "SAVING...";

            //--------Because For normal user find courier button is hidden-----
            if (this.auth().user_type == 'user') {
                await this.findCouriers();
            }
            //--------End-------

            if (this.$route.params.id) {
                await this.axios
                    .post(
                        "/api/draft/quotebook/dispatch-new/" + this.$route.params.id,
                        this.draft
                    )
                    .then((response) => {
                        console.log(response);
                        this.$router.push("/pages/Draft");
                        this.toast.success(response.data.msg);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    })
                    .catch((error) => {
                        console.log(error);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    });
            } else {
                await this.axios
                    .post("/api/draft/quotebook/dispatch-new", this.draft)
                    .then((response) => {
                        console.log(response);
                        this.$router.push("/pages/Draft");
                        this.toast.success(response.data.msg);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    })
                    .catch((error) => {
                        console.log(error);
                        this.save_btn.disabled = false;
                        this.save_btn.value = "SAVE AS DRAFT";
                    });
            }
        },
        enableEditMode() {
            console.log(this.draft.type_of_good);
            this.enable_edit_var = true;
            this.findcourier_btn_edit = false;
        },
        closeConsignment() {
            this.viewconsignmentpopup_booking_id = "";
            this.$router.push("/pages/ViewCourierPending");
            this.courier = this.courier.map((element) => {
                element.show_view_popup = false;
                return element;
            });
        },
        validatePickupDateTime() {

            const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
            const now = moment(moment().format(dateTimeFormat), dateTimeFormat);
            const date = moment(`${this.draft.pickup_date} ${this.draft.pickup_time}:00`, dateTimeFormat);
            const start = moment(`${this.draft.pickup_date} ${this.window_pickup_from_time}`, dateTimeFormat);
            const end = moment(`${this.draft.pickup_date} ${this.window_pickup_to_time}`, dateTimeFormat);
            if (!this.today_pickup) {
                return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(now.format('YYYY-MM-DD')) && date.isBetween(start, end);
            } else {
                return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(now.format('YYYY-MM-DD')) && date.isBetween(start, end);
            }
        },
        validateInternationPickupDateTime() {
            const dateFormat = 'YYYY-MM-DD';
            const now = moment(moment().format(dateFormat), dateFormat);
            const date = moment(this.draft.pickup_date, dateFormat);

            return moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD').isAfter(now.format('YYYY-MM-DD')) && date.isSameOrBefore(now.add(10, 'd'));
        },
        checkDateIfToday(value) {
            const dateTimeFormat = 'YYYY-MM-DD';
            const now = moment(moment().format(dateTimeFormat), dateTimeFormat);
            const date = moment(value[0], 'YYYY-MM-DD').format('YYYY-MM-DD');
            const isDateBefore = moment(date, 'YYYY-MM-DD').isBefore(now.format('YYYY-MM-DD'));
            const isDateSameOrBefore = moment(date, 'YYYY-MM-DD').isSameOrBefore(now.format('YYYY-MM-DD'));
            const isMoreThan10DaysInFuture = moment(date, 'YYYY-MM-DD').isAfter(now.add(10, 'd'));

            if (this.draft.carrier_service_type == this.serviceInternational) {
                if (this.draft.carrier_code == "DHL") {
                    if (isMoreThan10DaysInFuture) {
                        this.toast.error("The Pickup Shipment Date cannot be more than 10 days in future.");
                        return;
                    }
                    if (isDateSameOrBefore) {
                        this.toast.error("The Pickup Shipment Date cannot be in the past or today.");
                        return;
                    }
                }
            }
            else {
                if (isDateBefore) {
                    this.toast.error("The Pickup Shipment Date cannot be in the past.");
                    return;
                }
            }

            this.today_pickup = moment(date, 'YYYY-MM-DD').isSame(now.format('YYYY-MM-DD'));
        },
        toggleTodayPickup(event) {
            this.today_pickup = event.target.checked;

            if (this.$route.params.id != undefined) {
                if (this.today_pickup) {
                    this.draft.pickup_date = moment().format("YYYY-MM-DD");
                    this.draft.pickup_time = moment().format("HH:mm:ss");
                } else {
                    this.draft.pickup_date = this.temp_pickup_date;
                    this.draft.pickup_time = this.temp_pickup_time;
                }
            }
        },
        async saveToAddressBook() {
            try {
                if (this.save_address.internal || this.save_address.external) {
                    await this.axios.post(
                        "/api/address/createFromDeliveryAddress",
                        {
                            category: this.save_address.external
                                ? "external"
                                : this.save_address.internal
                                    ? "internal"
                                    : "",
                            name: this.draft.delivery_name,
                            company_name: this.draft.delivery_company,
                            branch_name: this.draft.delivery_branch,
                            department_id: this.draft.delivery_department,
                            cost_center_id: "",
                            delivery_location: "",
                            address_1: this.draft.delivery_address_1,
                            address_2: this.draft.delivery_address_2,
                            postal_code: this.draft.delivery_postal_code,
                            country: this.draft.delivery_country,
                            city: this.draft.delivery_city,
                            suburb: this.draft.delivery_suburb,
                            state: this.draft.delivery_state,
                            phone: this.draft.delivery_phone,
                            email: this.draft.delivery_email,
                            preferred_address: "0",
                            preferred: {
                                address_1: "",
                                address_2: "",
                                postal_code: "",
                                country: "",
                                city: "",
                                suburb: "",
                                state: "",
                            },
                        }
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchMatterNumber() {
            try {
                let localMatterNumbers = [];
                localMatterNumbers = localStorage.getItem('matter_numbers');

                if (localMatterNumbers) {
                    localMatterNumbers = JSON.parse(localMatterNumbers);
                    if (localMatterNumbers.length > 0) {
                        this.matter_numbers = localMatterNumbers;
                        this.draft.matter_number_id = 0;
                        return;
                    }
                }

                const startTime = new Date().getTime();
                const matterNumberResponse$ = await this.axios.get('/api/mater-numbers');
                const endTime = new Date().getTime();
                console.log('12 FetchMatterNumber Response time:', endTime - startTime, 'ms');
                if (matterNumberResponse$) {
                    this.matter_numbers = [];
                    const matterNumbers = matterNumberResponse$.data.matter_number;
                    this.matter_numbers.push({
                        id: 0,
                        label: 'No Matter Number',
                    });
                    matterNumbers.forEach((matterNumber) => {
                        this.matter_numbers.push({
                            id: matterNumber.id,
                            label: matterNumber.matter_number,
                        });
                    });

                    localStorage.setItem('matter_numbers', JSON.stringify(this.matter_numbers));

                    this.draft.matter_number_id = 0;
                }
            }
            catch (err) {
                console.log(err);
            }
        },
        async fetchGlCode() {
            try {
                const startTime = new Date().getTime();
                const glCodeResponse$ = await this.axios.get('/api/glcode');
                const endTime = new Date().getTime();
                console.log('14 FetchGlCode (auth == user) Response time:', endTime - startTime, 'ms');
                if (glCodeResponse$) {

                    if (glCodeResponse$.data.gl_code) {

                        this.draft.gl_code = glCodeResponse$.data.gl_code;
                        this.draft.custom_code = 'Gl Code';
                    }

                }
            }
            catch (err) {
                console.log(err);
            }
        },
        setSelectedMatterNumber(value) {

            this.draft.matter_number_id = '';
            if (typeof value !== 'undefined' && value != null) {
                this.draft.matter_number_id = typeof value.id !== 'undefined' ? value.id : value;
            }
        },
        async onPackageTypeChange(event) {
            await this.fetchDispatch();

            await this.setPackageType(event.target.value);
        },
        async setPackageType(type_id) {
            const packageTypeName = this.goods.find((type) => type.id === type_id);

            if (!packageTypeName) {
                return;
            }

            const package_type = packageTypeName.type;

            this.draft.package_type = package_type;

            if (this.draft.delivery_service_type == 'International') {

                if (Array.isArray(this.draft.packages) && this.draft.packages.length > 0) {

                    this.draft.packages.forEach(pkg => {

                        pkg.type = package_type;

                        if (Array.isArray(pkg.lineItems) && pkg.lineItems.length > 0) {
                            pkg.lineItems.forEach(lineItem => {
                                lineItem.item_type = package_type;

                                if (this.$route.params.id == undefined) {
                                    lineItem.item_description = (this.draft.delivery_service_type === 'International' && this.draft.package_type === 'Parcel')
                                        ? ""
                                        : package_type;
                                }

                            });
                        }
                    });

                }
            }

        },
        async onServiceTypeChange(event) {
            await this.setDeliveryServiceType(event.target.value);
        },
        async setDeliveryServiceType(serviceTypeId) {
            const serviceTypeName = this.servicetypes.find((serviceType) => serviceType.id == serviceTypeId);
            this.draft.delivery_service_type = serviceTypeName.service_type;
            await this.fetchPackageType();
            if ('Postal' == serviceTypeName.service_type) {
                this.draft.priority = 'Standard'
                this.draft.carrier_service_type = serviceTypeName.id
                this.isDomesticSelected = false;
                this.draft.client = 'Contract';

                return;
            }

            if ('Domestic' == serviceTypeName.service_type) {
                this.isDomesticSelected = true;
            } else {
                this.isDomesticSelected = false;
            }

            if (Array.isArray(this.draft.packages) && this.draft.packages.length > 0) {

                if (this.draft.delivery_service_type == 'International') {

                    this.draft.packages = [this.draft.packages[0]];

                    var type = this.draft.package_type;

                    const defaultValues = {
                        item_reference: "NA",
                        item_type: type,
                        item_description: type,
                        item_charge_unit: "cm",
                        item_qty: "",
                        item_weight: "",
                        item_length: "",
                        item_width: "",
                        item_height: "",
                        item_m3: 0,
                        item_cubic_kg: 0,
                        item_is_dg: 0,
                        export_reason_type: "permanent",
                        commodity_type_code: null,
                        commodity_code_value: '',
                        customer_ref_type_code: 'AAJ',
                        customer_ref_type_value: '',
                        unit_measurement: 'BOX',
                        is_tax_paid: "false",
                        product_code: "",
                        country_code: "PH",
                        commercial_value: "",
                        insured_value: "",
                        currency: "PHP",
                        booking_id: "",
                        id: "",
                        carrier_code: this.draft.carrier_code,
                    };

                    this.draft.packages[0].lineItems.push(defaultValues);

                } else {
                    this.draft.packages[0].lineItems = [];
                    this.draft.packages = [this.draft.packages[0]];
                }
            }

            this.draft.priority = 'Standard'
        },
        selectCustomCode(customCode) {
            this.disable_gl_code = false;
            this.draft.gl_code = null;
            this.gl_code_placeholder = customCode;
            this.draft.custom_code = customCode;
        },
        setSelectedCountry(value) {
            this.draft.delivery_country = '';
            this.draft.delivery_country_code = '';
            if (typeof value !== 'undefined' && value != null) {
                this.draft.delivery_country = typeof value.label !== 'undefined' ? value.label : this.countries.find((country) => country.code == value).name;
                this.draft.delivery_country_code = typeof value.id !== 'undefined' ? value.id : this.countries.find((country) => country.code == value).code;
            }
        },
        async getCountries() {
            try {

                this.lcountries.push({
                    id: '',
                    label: 'Select Country',
                });

                let countries = await this.setCountries();
                countries = countries.filter((item, index) => {
                    return countries.indexOf(item) == index;
                });

                for (let i = 0; i < countries.length; i++) {
                    this.lcountries.push({
                        id: countries[i].code,
                        label: countries[i].name,
                    });
                }
            }
            catch (e) {
                console.log(e);
            }
        },
        async populateDraft(id) {

            try {
                const response = await this.axios.get(`/api/draft/new/${id}`);

                this.userSelectedServices = response.data.user_booking_services;

                // set package type
                await this.setPackageType(response.data.draft.type_of_good);

                // delivery service type
                await this.setDeliveryServiceType(response.data.draft.carrier_service_type);

                this.draft.packages = [];

                // Load packages details
                const { courier_items } = response.data.draft;

                if (courier_items.length > 0) {
                    courier_items.forEach((pkg, p) => {

                        var lineItems = [];

                        //Load line items inside package
                        const { courier_line_items } = pkg;

                        if (courier_line_items.length > 0) {
                            courier_line_items.forEach((item, i) => {
                                const lineItem = {
                                    item_reference: item.item_reference,
                                    item_type: item.item_type,
                                    item_description: item.item_description,
                                    item_charge_unit: item.item_charge_unit,
                                    item_qty: item.item_qty,
                                    item_weight: item.item_weight,
                                    item_length: item.item_length,
                                    item_width: item.item_width,
                                    item_height: item.item_height,
                                    item_m3: item.item_m3,
                                    item_cubic_kg: item.item_cubic_kg,
                                    item_is_dg: item.item_is_dg,
                                    product_code: item.product_code,
                                    export_reason_type: item.export_reason_type,
                                    country_code: item.country_code,
                                    commercial_value: item.commercial_value,
                                    insured_value: item.insured_value,
                                    currency: item.currency,
                                    invoice_no: item.invoice_no,
                                    invoice_date: item.invoice_date,
                                    booking_id: item.booking_id,
                                    id: item.id,
                                    courier_item_id: item.courier_item_id,
                                    export_reason_type: this.revertTransformString(item.export_reason_type),
                                    commodity_type_code: item.commodity_type_code,
                                    commodity_code_value: item.commodity_code_value,
                                    customer_ref_type_code: item.customer_ref_type_code,
                                    customer_ref_type_value: item.customer_ref_type_value,
                                    unit_measurement: item.unit_measurement,
                                    is_tax_paid: item.is_tax_paid == 1 ? 'true' : 'false',
                                };

                                lineItems.push(lineItem);
                            });
                        }

                        this.draft.packages.push({
                            booking_id: pkg.booking_id,
                            id: pkg.id,
                            type: pkg.item_type,
                            unit_measurement: pkg.unit_measurement,
                            weight: pkg.item_weight,
                            length: pkg.item_length,
                            width: pkg.item_width,
                            height: pkg.item_height,
                            m3: pkg.item_m3,
                            lineItems: lineItems
                        });

                    });
                } else {

                    this.draft.packages.push({
                        type: this.draft.package_type,
                        unit_measurement: 'cm',
                        weight: '',
                        length: '',
                        width: '',
                        height: '',
                        m3: 0,
                        lineItems: []
                    });
                }

                //carrier list shown on edit
                this.ratecouriers = [];
                if (response.data.draft.provider && response.data.draft.provider.id != null) {
                    this.ratecouriers[0] = {
                        is_carrier_preference: response.data.draft.provider.is_carrier_preference,
                        logo: response.data.draft.provider.logo,
                        name: response.data.draft.provider.name,
                        is_checked: true,
                        service_name: response.data.draft.delivery_service,
                        transit_time: response.data.draft.transit_time,
                        is_dg: response.data.draft.provider.is_dg,
                        cost: response.data.draft.cost,
                        description: response.data.draft.provider.description,
                        sell_rate_margin: response.data.draft.provider.sell_rate_margin,
                        sla: response.data.draft.provider.sla,
                        provider_id: response.data.draft.provider.id,
                        service_id: response.data.draft.service_id,
                        carrier_code: response.data.draft.carrier_code,
                        courier_rate: response.data.draft.courier_rate,
                        total_price_with_gst: response.data.draft.total_price_with_gst,
                        fuel_surcharge: response.data.draft.fuel_surcharge,
                        handling_fee: response.data.draft.handling_fee,
                        total_price: response.data.draft.total_price,
                        gst_value: response.data.draft.gst_value,
                        price_without_GST: response.data.draft.price_without_GST,
                        handling_fee_percentage: response.data.draft.handling_fee_percentage,
                    };

                    this.SelectCourier(this.ratecouriers[0]);
                }

                this.customerRefenceNo();

                if (response.data.address_book_id && response.data.address_book_id.length > 0) {
                    this.mapDeliveryAddresses(
                        response.data.address_book_id,
                        false
                    );
                    this.draft.address_book_id = response.data.address_book_id[0].id ? response.data.address_book_id[0].id : "";
                } else {
                    this.draft.address_book_id = "sbdhcbwkds";
                }

                //set category in edit mode
                this.draft.delivery_address_category = response.data.draft.delivery_address_category;
                this.draft.pickup_address_category = response.data.draft.pickup_address_category;

                setTimeout(() => {
                    const courierData = response.data.courier;
                    if (courierData && courierData.window_schedule) {
                        this.window_pickup_from_time = courierData.window_schedule.from;
                        this.window_pickup_to_time = courierData.window_schedule.to;
                    }
                }, 1000);

                const countries = JSON.parse(localStorage.getItem('countries'));
                const selectedDeliveryCountry = countries.find((country) => {
                    return country.name == this.draft.delivery_country;
                });
                const selectedPickupCountry = countries.find((country) => {
                    return country.name == this.draft.pickup_country;
                });

                this.draft.delivery_country_code = selectedDeliveryCountry ? selectedDeliveryCountry.code : '';
                this.draft.pickup_country_code = selectedPickupCountry ? selectedPickupCountry.code : '';

                setTimeout(() => {

                    if (response.data.draft.custom_code == 'Gl Code') {
                        this.draft.gl_code = response.data.draft.custom_code_value ? response.data.draft.custom_code_value : '';
                    } else if (response.data.draft.custom_code == 'Matter Number') {
                        this.draft.matter_number_id = response.data.draft.matter_number ? response.data.draft.matter_number.id : 0;
                        this.draft.matter_number = response.data.draft.matter_number ? response.data.draft.matter_number.matter_number : '';
                    } else {
                        this.draft.gl_code = response.data.draft.custom_code_value;
                    }

                    this.draft.custom_code = response.data.draft.custom_code;

                    setTimeout(() => {
                        this.findcourier_btn_edit = true;
                        this.delivery_addresses_count = response.data.count;
                        this.draft.entry_type = response.data.draft.entry_type;
                        this.draft.request_date = response.data.draft.request_date;
                        this.draft.requester_name = response.data.draft.requester_name;
                        this.draft.branch_id = response.data.draft.branch_id;
                        this.draft.type_of_good = response.data.draft.type_of_good;
                        this.draft.carrier_service_type = response.data.draft.carrier_service_type;
                        this.draft.weight = response.data.draft.weight;
                        this.draft.mode_of_dispatch = response.data.draft.mode_of_dispatch;
                        this.draft.carrier_code = response.data.draft.carrier_code;
                        this.draft.service_id = response.data.draft.service_id;
                        this.draft.pickup_name = response.data.draft.pickup_name;
                        this.draft.pickup_company = response.data.draft.pickup_company;
                        this.draft.pickup_branch = response.data.draft.pickup_branch;
                        this.draft.pickup_floor = response.data.draft.pickup_floor;
                        this.draft.pickup_desk = response.data.draft.pickup_desk;
                        this.draft.pickup_address_1 = response.data.draft.pickup_address_1;
                        this.draft.pickup_address_2 = response.data.draft.pickup_address_2;
                        this.draft.pickup_city = response.data.draft.pickup_city;
                        this.draft.pickup_state = response.data.draft.pickup_state;
                        this.draft.pickup_suburb = response.data.draft.pickup_suburb;
                        this.draft.pickup_postal_code = response.data.draft.pickup_postal_code;
                        this.draft.pickup_country = response.data.draft.pickup_country;
                        this.draft.pickup_country_code = response.data.draft.pickup_country_code;
                        this.draft.pickup_phone = response.data.draft.pickup_phone;
                        this.draft.pickup_mobile = response.data.draft.pickup_mobile;
                        this.draft.pickup_email = response.data.draft.pickup_email;
                        this.draft.delivery_name = response.data.draft.delivery_name;
                        this.draft.delivery_branch = response.data.draft.delivery_branch;
                        this.draft.delivery_company = response.data.draft.delivery_company;
                        this.draft.delivery_floor = response.data.draft.delivery_floor;
                        this.draft.delivery_desk = response.data.draft.delivery_desk;
                        this.draft.delivery_address_1 = response.data.draft.delivery_address_1;
                        this.draft.delivery_address_2 = response.data.draft.delivery_address_2;
                        this.draft.delivery_city = response.data.draft.delivery_city;
                        this.draft.delivery_state = response.data.draft.delivery_state;
                        this.draft.delivery_suburb = response.data.draft.delivery_suburb;
                        this.draft.delivery_postal_code = response.data.draft.delivery_postal_code;
                        this.draft.delivery_country = response.data.draft.delivery_country;
                        this.draft.delivery_country_code = response.data.draft.delivery_country_code;
                        this.draft.delivery_phone = response.data.draft.delivery_phone;
                        this.draft.delivery_mobile = response.data.draft.delivery_mobile;
                        this.draft.delivery_email = response.data.draft.delivery_email;
                        this.draft.reference_no = response.data.draft.reference_no;
                        this.draft.goods_description = response.data.draft.goods_description;
                        this.draft.pickup_instructions = response.data.draft.pickup_instructions;
                        this.draft.remarks = response.data.draft.remarks;
                        this.draft.office_close_time = response.data.draft.office_close_time;
                        this.draft.pickup_date = moment(response.data.draft.pickup_date, "DD.MM.YYYY").format("YYYY-MM-DD");
                        this.draft.pickup_time = response.data.draft.pickup_time;
                        this.temp_pickup_date = moment(response.data.draft.pickup_date, "DD.MM.YYYY").format("YYYY-MM-DD");
                        this.draft.is_courier_pickup_date_required = response.data.draft.is_courier_pickup_date_required,
                            this.draft.is_courier_pickup_time_required = response.data.draft.is_courier_pickup_time_required,
                            this.temp_pickup_time = response.data.draft.pickup_time;
                        this.draft.id = id;
                        this.draft.client = response.data.draft.client;
                        this.draft.payer_vat_number = response.data.draft.payer_vat_number,
                            this.draft.recipient_reference = response.data.draft.recipient_reference,
                            this.draft.shipment_type = response.data.draft.shipment_type,
                            this.draft.signature_name = response.data.draft.signature_name,
                            this.draft.signature_title = response.data.draft.signature_title,
                            this.draft.gross_weight = response.data.draft.gross_weight,
                            this.draft.net_weight = response.data.draft.net_weight
                        this.draft.client = response.data.draft.client || 'Contract';
                        this.draft.movement_type = response.data.draft.movement_type;

                        this.draft.invoice_no = response.data.draft.invoice_no;
                        this.draft.invoice_date = response.data.draft.invoice_date || moment(new Date()).format("YYYY-MM-DD");

                        this.isExternalPickup();

                        // this.fetchAvailableServices();

                        if (response.data.draft.user_selected_services.length > 0) {
                            const service_arr = response.data.draft.user_selected_services.map((service) => {
                                return service.id;
                            });

                            this.draft.user_selected_services = service_arr;
                        }

                        const now = moment();
                        let date = moment(this.draft.pickup_date + " " + this.draft.pickup_time);

                        if (date.isBefore(now, 'day')) {
                            this.draft.pickup_date = now.format('YYYY-MM-DD');
                            this.draft.pickup_time = now.format('HH:mm:ss');
                            this.today_pickup = true;
                        } else if (date.isSame(now, 'day')) {
                            this.today_pickup = true;
                        } else {
                            this.today_pickup = false;
                        }

                    }, 500);
                }, 1000);
            }
            catch (error) {
                console.log(error);
            }

            if (this.enable_edit_var) {
                setTimeout(() => {
                    this.package_details_var = false;
                }, 1000);
            }
        },
        async fetchAvailableServices() {

            this.draft.user_selected_services = [];

            try {

                var pickup_state = this.draft.pickup_state;
                var delivery_state = this.draft.delivery_state;

                if (!pickup_state) {

                    this.toast.error("Pickup state must not be empty");

                    return;
                }

                if (!this.draft.delivery_address_1) {

                    this.toast.error("Please select delivery address");

                    return;
                }

                if (!delivery_state) {

                    this.toast.error("Delivery state must not be empty");

                    return;
                }

                if (!this.draft.delivery_country) {

                    this.toast.error("Delivery country must not be empty");

                    return;
                }

                this.available_service_btn.disable = true;
                this.available_service_btn.value = "Searching...";

                if (this.draft.pickup_country.toLowerCase() != this.draft.delivery_country.toLowerCase()) {
                    delivery_state = 'International';
                }

                let couriers = await this.setAvailableCourierProviders({
                    pickup_state: pickup_state,
                    delivery_state: delivery_state,
                });

                if (couriers.length < 1) {

                    this.toast.error("No services available for the selected address");

                    this.availableCourierServices = [];

                    return;
                }

                this.availableCourierServices = couriers;


            }
            catch (e) {
                console.log(e);
            }
            finally {
                this.available_service_btn.disable = false;
                this.available_service_btn.value = "Show Available Services";
            }
        },
        capitalizeFirstLetter(string) {
            if (typeof string !== 'string' || string.length === 0) {
                return string;
            }

            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        transformString(string) {
            // Replace underscores with spaces
            string = string.replace(/_/g, ' ');

            // Capitalize the first letter of each word
            string = string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return string;
        },
        revertTransformString(string) {
            // Convert each word's first letter to lowercase
            string = string.split(' ').map(word => word.charAt(0).toLowerCase() + word.slice(1)).join(' ');

            // Replace spaces with underscores
            string = string.replace(/ /g, '_');

            return string;
        },
        convertToAmPm(time) {
            // Create a new Date object with the given time
            let [hours, minutes, seconds] = time.split(':');
            let date = new Date();
            date.setHours(hours, minutes, seconds);

            // Extract hours, minutes, and seconds
            let formattedHours = date.getHours();
            let formattedMinutes = date.getMinutes().toString().padStart(2, '0');
            let formattedSeconds = date.getSeconds().toString().padStart(2, '0');
            let period = 'AM';

            // Determine AM or PM period and adjust hours if necessary
            if (formattedHours >= 12) {
                period = 'PM';
                if (formattedHours > 12) formattedHours -= 12;
            } else if (formattedHours === 0) {
                formattedHours = 12;
            }

            // Format hours to always have two digits
            formattedHours = formattedHours.toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes} ${period}`;
        },
        getGLCode(data) {
            return `${data.office}-${data.entity}-${data.department}-${data.section}-000`
        },
        isExternalPickup() {

            if (this.auth().user_type == 'user') {
                return;
            }

            var pickupAddress = this.convertObjectValuesToLowerCase({
                floor: this.draft.pickup_floor,
                desk: this.draft.pickup_desk,
                address_1: this.draft.pickup_address_1,
                address_2: this.draft.pickup_address_2,
                postal_code: this.draft.pickup_postal_code,
                city: this.draft.pickup_city,
                suburb: this.draft.pickup_suburb,
                state: this.draft.pickup_state,
                country: this.draft.pickup_country,
            });

            var userBranchAddress = this.convertObjectValuesToLowerCase(this.userBranchAddress);

            const userPickupFromHubLocation = this.isAddressEqual(pickupAddress, userBranchAddress);

            this.hide_ssit = (userPickupFromHubLocation) ? false : true;
        },
        isAddressEqual(address1, address2) {
            return (
                address1.address_1 === address2.address_1 &&
                address1.postal_code === address2.postal_code &&
                address1.city === address2.city &&
                address1.suburb === address2.suburb &&
                address1.state === address2.state &&
                address1.country === address2.country
            );
        },
        convertObjectValuesToLowerCase(obj) {
            const lowerCasedObj = {};

            for (const key in obj) {
                if (obj.hasOwnProperty(key) && typeof obj[key] === 'string') {
                    lowerCasedObj[key] = obj[key].toLowerCase();
                } else if (obj[key] == null) {
                    lowerCasedObj[key] = "";
                } else {
                    lowerCasedObj[key] = obj[key];
                }
            }

            return lowerCasedObj;
        },
        validatePackageData() {

            var package_issue = false;
            const regex = /^[^@$%^&*ñ()_+":~`<>;#]+$/;

            if (this.draft.packages.length > 0) {

                for (const [p, pkg] of this.draft.packages.entries()) {

                    var weight = pkg.weight;
                    var current_weight = 0;

                    if (pkg.length == '' || pkg.length == null) {

                        this.toast.error(`Package ${p + 1} Length field can not be empty`);

                        package_issue = true;

                        break;
                    }

                    if (this.draft.carrier_service_type == this.serviceInternational && parseFloat(pkg.length) < 1) {
                        this.toast.error(`Package ${p + 1} Length must be greater than or equal to 1`);

                        package_issue = true;

                        break;
                    }

                    if (this.draft.carrier_service_type == this.serviceInternational && parseFloat(pkg.length) > 120.00) {
                        this.toast.error(`Package ${p + 1} Length exceeds maximum of 120cm. Please contact support team`);

                        package_issue = true;

                        break;
                    }

                    if (pkg.width == '' || pkg.width == null) {

                        this.toast.error(`Package ${p + 1} Width field can not be empty`);

                        package_issue = true;

                        break;
                    }

                    if (this.draft.carrier_service_type == this.serviceInternational && parseFloat(pkg.width) < 1) {
                        this.toast.error(`Package ${p + 1} Width must be greater than or equal to 1`);

                        package_issue = true;

                        break;
                    }

                    if (this.draft.carrier_service_type == this.serviceInternational && parseFloat(pkg.width) > 120.00) {
                        this.toast.error(`Package ${p + 1} Width exceeds maximum of 120cm. Please contact support team`);

                        package_issue = true;

                        break;
                    }

                    if (pkg.height == '' || pkg.height == null) {

                        this.toast.error(`Package ${p + 1} Height field can not be empty`);

                        package_issue = true;

                        break;
                    }

                    if (this.draft.carrier_service_type == this.serviceInternational && parseFloat(pkg.height) < 1) {
                        this.toast.error(`Package ${p + 1} Height must be greater than or equal to 1`);

                        package_issue = true;

                        break;
                    }

                    if (this.draft.carrier_service_type == this.serviceInternational && parseFloat(pkg.height) > 120.00) {
                        this.toast.error(`Package ${p + 1} Height exceeds maximum of 120cm. Please contact support team`);

                        package_issue = true;

                        break;
                    }

                    if (weight == '' || weight == null) {
                        this.toast.error(
                            `Package ${p + 1} weight can not be empty.`
                        );

                        package_issue = true;

                        break;
                    }

                    weight = parseFloat(weight);

                    if (this.draft.carrier_service_type == this.serviceInternational) {

                        if (this.draft.type_of_good == this.goodsDocument) {
                            if (weight > 0.75) {
                                this.toast.error(
                                    `Package ${p + 1} is too heavy to be sent as a Document. Please select the Parcel option.`
                                );

                                package_issue = true;

                                break;
                            }
                        }

                        if (this.draft.type_of_good == this.goodsParcel) {
                            if (weight > 70) {
                                this.toast.error(
                                    `Package ${p + 1} weight exceeds maximum of 70kg. Please contact support team.`,
                                );

                                package_issue = true;

                                break;
                            }
                        }
                    }

                    if (pkg.lineItems.length > 0) {

                        for (const [i, item] of pkg.lineItems.entries()) {
                            var item_weight = parseFloat(item.item_weight);

                            const item_description = item.item_description;

                            if (item_description == '' || item_description == null) {

                                this.toast.error(
                                    `Package ${p + 1} Item ${i + 1} description can not be empty.`
                                );

                                package_issue = true;

                                break;
                            }

                            if (!regex.test(item_description)) {

                                this.toast.error(
                                    `Package ${p + 1} Item ${i + 1} description format invalid.`
                                );

                                package_issue = true;

                                break;
                            }

                            if (item.item_qty == '' || item.item_qty == null) {
                                this.toast.error(
                                    `Package ${p + 1} Item ${i + 1} quantity can not be empty.`
                                );

                                package_issue = true;

                                break;
                            }

                            if (item.item_weight == '' || item.item_weight == null) {
                                this.toast.error(
                                    `Package ${p + 1} Item ${i + 1} weight can not be empty.`
                                );

                                package_issue = true;

                                break;
                            }

                            if ((current_weight + item_weight) > weight) {

                                this.toast.error(
                                    `The ${pkg.lineItems.length > 1 ? 'sum of item weights' : 'item weight'} must be less than or equal to the package ${p + 1} weight.`
                                );

                                package_issue = true;

                                break;
                            }

                            current_weight += item_weight;

                            if (item.item_length == '' || item.item_length == null) {

                                this.toast.error(`Package ${p + 1}  Item ${i + 1} Length field can not be empty`);

                                package_issue = true;

                                break;
                            }

                            if (item.item_width == '' || item.item_width == null) {

                                this.toast.error(`Package ${p + 1} Item ${i + 1} Width field can not be empty`);

                                package_issue = true;

                                break;
                            }

                            if (item.item_height == '' || item.item_height == null) {

                                this.toast.error(`Package ${p + 1} Item ${i + 1} Height field can not be empty`);

                                package_issue = true;

                                break;
                            }

                            if (this.draft.type_of_good == this.goodsParcel) {

                                if (item.commercial_value == '' || item.commercial_value == null) {

                                    this.toast.error(`Package ${p + 1} Item ${i + 1} Commercial Value field can not be empty`);

                                    package_issue = true;

                                    break;
                                }

                                if (item.commodity_code_value == '' || item.commodity_code_value == null) {

                                    this.toast.error(`Package ${p + 1} Item ${i + 1} Commodity Code Value field can not be empty`);

                                    package_issue = true;

                                    break;
                                }


                                if (item.customer_ref_type_value == '' || item.customer_ref_type_value == null) {

                                    this.toast.error(`Package ${p + 1} Item ${i + 1} Customer Ref Type Code Value field can not be empty`);

                                    package_issue = true;

                                    break;
                                }

                            }

                            if (this.draft.carrier_service_type != this.serviceInternational || this.draft.package_type !== 'Parcel') {

                                item.commodity_type_code = null;
                                item.commodity_code_value = null;
                                item.customer_ref_type_code = null;
                                item.customer_ref_type_value = null;
                                item.unit_measurement = null;
                                item.is_tax_paid = null;
                            }
                        }
                    }
                }
            }

            return package_issue;
        },
        showData() {
            console.log(this.draft)
        }
    },
    watch: {
        'draft.pickup_address_1': function (newValue, oldValue) {
            this.availableCourierServices = [];
        },
        'draft.delivery_address_1': function (newValue, oldValue) {
            this.availableCourierServices = [];
        },
        'draft.delivery_state': function (newValue, oldValue) {
            this.availableCourierServices = [];
        },
        'draft.delivery_country': function (newValue, oldValue) {
            this.availableCourierServices = [];
        },
    },
};
</script>
<style scoped>
.v_align_top {
    vertical-align: top;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.w40 {
    width: 40% !important;
}

.service_details_container {
    padding: 20px;
}

.service_details_container>section {
    padding: 20px 0 0 0;
}

.service_details_container>section h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: inline-block;
}

.title_image {
    display: block;
    margin: auto;
}

h4 {
    font-weight: 600;
    text-align: center;
    padding: 30px 0;
    font-size: 18px;
}

.step_view_wrapper {
    width: 100%;
    height: 140px;
    background: #181818;
    padding: 20px 80px;
    position: relative;
}

.step_view_wrapper .strip {
    width: calc(100% - 160px);
    height: 10px;
    background: #666;
    position: absolute;
    top: 50%;
    line-height: 0;
}

.step_view_wrapper .strip_half {
    height: 10px;
    width: 50%;
    background: #06a5ed;
    display: inline-block;
    display: none;
}

.step_view_wrapper .circle {
    width: 30px;
    height: 30px;
    background: #000;
    border: 5px solid #06a5ed;
    border-radius: 100px;
    position: absolute;
    left: -10px;
    top: -10px;
}

.step_view_wrapper .circle.inactive {
    background: #ffffff;
    border-color: #999;
}

.step_view_wrapper .circle.two {
    left: 50%;
    margin-left: -10px;
}

.step_view_wrapper .circle.three {
    left: 100%;
    margin-left: -10px;
}

.step_view_wrapper .circle_labels {
    font-size: 14px;
    text-align: center;
    color: #fff;
    position: absolute;
    width: 120px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    top: 40px;
    left: -60px;
}

.radio_wrapper1 {
    width: 300px;
    height: 100px;
    background: #fcfcfc;
    display: flex;
    padding: 20px 0;
}

.radio_wrapper1 label {
    width: auto;
    height: 30px;

    display: block;
    margin: 5px;
}

.radio_wrapper1 label input[type="radio"] {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}

.radio_wrapper1 label span {
    width: auto;
    height: 20px;
    display: inline-block;
    margin-right: 30px;
    position: relative;
    top: -5px;
}

.radio_with_img_labels {
    width: 180px;
    height: 80px;
    background: #fff;
    color: #333;
    padding: 5px;
    margin: 10px 20px;
    display: inline-block;
}

.radio_with_img_labels img {
    display: block;
    margin: auto;
    height: 40px;
}

.radio_with_img_labels input[type="radio"] {
    width: 20px;
    height: 20px;
    display: block;
    margin: auto;
}

.special_panel {
    background: #fff;
    width: 100%;
}

.special_panel header {
    height: 40px;
    background: #333;
    color: #ccc;
    font-size: 16px;
    line-height: 40px;
    padding: 0 20px;
}

.special_panel .sp_body {
    background: #fff;
    padding: 20px;
}

.special_panel .sp_body .a_part {
    width: 50%;
    display: inline-block;
    position: relative;
    height: 100%;
}

.special_panel .sp_body .a_part .infos {
    padding: 20px 0;
    color: #333;
    font-size: 16px;
}

.special_panel .sp_body .a_part .view_rate_card {
    display: block;
    background: #06a5ed;
    color: #fff;
    width: 160px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    right: 20px;
    bottom: -125px;
}

.label_and_element_wrapper.sp {
    width: 70%;
}

.lone_chk_label {
    padding: 20px;
    display: inline-block;
}

.lone_chk_label span {
    display: inline-block;
    position: relative;
    top: -5px;
}

.lone_chk_label input {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.address_details_wrapper {
    width: 45%;
    height: auto;
    display: inline-block;
    margin: 0 2%;
}

.adjust_width_full_wrapper {
    width: 94%;
    height: auto;
    display: inline-block;
    margin: 0 2%;
}

.address_details_wrapper header {
    height: 60px;
}

.address_details_wrapper header h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: block;
    float: left;
}

.address_details_wrapper header .yellow_bt {
    font-size: 20px;
    padding: 0 10px;
    float: right;
    width: 200px;
    height: 40px;
    background: #004a7c;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    display: block;
}

.address_details_wrapper header .yellow_bt i {
    font-size: 24px;
}

.service_logo {
    width: 300px;
    height: auto;
    padding: 20px 0;
}

.service_logo img {
    display: block;
    width: 100%;
    height: auto;
}

.selected_count {
    width: 130px;
    height: 40px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 40px;
    color: rgb(8, 82, 243);
    float: right;
    box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    float: right;
    margin-right: 20px;
}

.selected_count .material-icons {
    font-size: 40px;
    top: -1px;
    left: -10px;
    position: relative;
    margin-right: 0;
}

.selected_count span {
    display: inline-block;
    position: relative;
    top: -13px;
    left: -8px;
}
</style>
<style scoped>
.form-text {
    color: #6c757d;
    font-size: 0.875em;
    margin-top: 0.25rem;
}

.label_and_element_wrapper.no-form-text {
    padding-bottom: 1.5rem !important;
}

.service_details_container {
    padding: 20px;
}

.service_details_container>section {
    padding: 20px 0 0 0;
}

.service_details_container>section h2 {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: inline-block;
}

.title_image {
    display: block;
    margin: auto;
}

h4 {
    font-weight: 600;
    text-align: center;
    padding: 30px 0;
    font-size: 18px;
}

.step_view_wrapper {
    width: 100%;
    height: 140px;
    background: #181818;
    padding: 20px 80px;
    position: relative;
}

.step_view_wrapper .strip {
    width: calc(100% - 160px);
    height: 10px;
    background: #666;
    position: absolute;
    top: 50%;
    line-height: 0;
}

.step_view_wrapper .strip_half {
    height: 10px;
    width: 50%;
    background: #06a5ed;
    display: inline-block;
    display: none;
}

.step_view_wrapper .circle {
    width: 30px;
    height: 30px;
    background: #000;
    border: 5px solid #06a5ed;
    border-radius: 100px;
    position: absolute;
    left: -10px;
    top: -10px;
}

.step_view_wrapper .circle.inactive {
    background: #ffffff;
    border-color: #999;
}

.step_view_wrapper .circle.two {
    left: 50%;
    margin-left: -10px;
}

.step_view_wrapper .circle.three {
    left: 100%;
    margin-left: -10px;
}

.step_view_wrapper .circle_labels {
    font-size: 14px;
    text-align: center;
    color: #fff;
    position: absolute;
    width: 120px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    top: 40px;
    left: -60px;
}

.label_and_element_wrapper.sp {
    width: 33%;
}

.lone_chk_label {
    padding: 20px;
    display: block;
}

.lone_chk_label input {
    display: inline-block;
    margin-right: 10px;
}

.service_logo {
    width: 300px;
    height: auto;
    padding: 20px 0;
}

.service_logo img {
    display: block;
    width: 100%;
    height: auto;
}

td.series_td {
    width: 220px;
}

table.tablce_cell_adj tr td {
    vertical-align: top;
    padding: 0;
}

table.tablce_cell_adj tr td input[type="checkbox"] {
    width: 20px;
    height: 20px;
    display: inline-block;
}

table.tablce_cell_adj tr td:nth-child(-n + 2) {
    width: auto;
}

table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(-n + 10) {
    width: 100px;
}

table.tablce_cell_adj tr td:nth-child(n + 3):nth-child(n + 11) {
    width: 60px;
}

.new-error {
    border: 1px solid #dc0a0a !important;
}

.new_yellow_edit_bt {
    margin-left: 10px;
    padding: 0 10px;
    float: left;
    width: 200px;
    height: 40px;
    background: #004a7c;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    display: block;
}

.user_type {
    padding: 10px;
}

._line_item {
    font-size: 20px;
    padding: 10px 0 10px 0;
    border-bottom: 4px solid #666;
    display: block;
    float: left;
}

._sur_overflow {
    overflow-x: hidden;
}

@media screen and (max-width: 1520px) {
    ._sur_overflow {
        overflow-x: scroll;
    }
}

.chk_standard {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 6px;
}

._sur_container {
    display: block;
    width: 100%;
    background: #eee;
}

._sur_flex_container {
    display: flex;
    padding: 20px;
    width: 100%;
    min-width: 1150px;
    justify-content: space-between;
}

._sur_flex_boxes {
    width: auto;
    min-width: 40px;
    display: flex;
    flex-direction: column;
    background: #eee;
    padding: 0;
}

._sur_flex_boxes:not(.action) {
    margin-right: 5px;
    min-height: 50px;
}

._sur_flex_boxes .label_and_element_wrapper {
    padding: 2px;
}

._sur_flex_boxes input[type="text"],
._sur_flex_boxes label,
._sur_flex_boxes select {
    min-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (min-width: 1700px) {
    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes {
        width: 100%;
    }

    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes.small {
        max-width: 100px;
    }

    ._sur_container ._sur_flex_container:nth-child(2) ._sur_flex_boxes {
        width: 100%;
    }

    ._sur_flex_boxes.adj_1920 {
        max-width: 40px;
    }
}

@media screen and (min-width: 1900px) {
    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes {
        width: 100%;
    }

    ._sur_container ._sur_flex_container:nth-child(1) ._sur_flex_boxes.small {
        max-width: 100px;
    }

    ._sur_container ._sur_flex_container:nth-child(2) ._sur_flex_boxes {
        width: calc(100% - 5px);
    }

    ._sur_flex_boxes.adj_1920 {
        max-width: 40px;
    }
}

.gl-input-group {
    display: flex;
    align-items: stretch;
    overflow: hidden;
}

.gl-input-field {
    border: none;
    padding: 10px;
    flex: 1;
    outline: none;
}

.radio-container {
    display: flex;
    flex-direction: column;
    /* margin: 20px; */
    padding: 10px;
}

.radio-heading {
    font-size: 18px;
    margin-bottom: 10px;
    color: #000;
    font-weight: bold;

}

.radio-options {
    display: flex;
    align-items: center;
}

.radio-option {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.radio-input {
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.radio-label {
    cursor: pointer;
}
</style>

<style>
/*becaue this is for this date component*/
._sur_flex_boxes .label_and_element_wrapper.width_adj input[type="text"] {
    max-width: 120px;
}

@media screen and (min-width: 1700px) {
    ._sur_flex_boxes .label_and_element_wrapper.width_adj input[type="text"] {
        max-width: calc(100% - 10px);
    }
}

.pickup-address-label {
    white-space: nowrap;
    margin-left: 15px;
    background-color: white;
    width: calc(100vw - 427px);
    height: auto;
    padding: 10px;
    display: block;
    border: 1px solid #ccc;
}

.delivery-address-label {
    background-color: rgb(0, 74, 124);
    padding: 10px 12px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
}

.special_panel_2 .courier_row {
    border-bottom: 1px solid #c0c0c0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.user_service_selection .user_service_selection_row {
    border-bottom: 1px solid #c0c0c0;
    justify-content: space-between;
}

.rate_break_down .rate_break_down_row {
    border-bottom: 2px solid #004A7C;
    justify-content: space-between;
}

.total-services {
    font-weight: bolder;
    padding: 12px;
    display: flex;
    justify-content: space-between;
}

.warning-border {
    border-color: red !important;
}

.find-couriers-container {
    position: relative;
    width: 60%;
}

.find-couriers-preloader {
    background: #06A5ED none repeat scroll 0% 0%;
    padding: 8px !important;
    font-weight: bold;
    right: -102px;
    bottom: 2px;
}

.preloader-container {
    text-align: center;
    margin-left: 6px;
}

.preloader-text {
    font-weight: bold;
}

.loader-container {
    display: flex;
    justify-content: center;
    margin-left: 6px;
}

.loader-image {
    height: 24px;
    margin-right: 5px;
}

.loader-message {
    padding-top: 2px;
    font-weight: bold;
}

.blue-box {
    background-color: #cfddea;
    padding: 20px;
    margin-bottom: 20px;
}

.blue-box p {
    margin: 0;
    color: #333;
}

.d-flex {
    display: flex;
}

.full-width {
    width: 100% !important;
}

.justfy-content-end {
    justify-content: end !important;
}

.justfy-content-center {
    justify-content: center !important;
}

.min-width-0 {
    min-width: 0 !important;
}

.min-width-70 {
    min-width: 70px !important;
}

.hs_code_link {
    font-weight: 400;
    color: rgb(6, 112, 193);
    font-size: 0.875rem;
    margin-top: 2px;
    margin-left: 1px;
}

.hs_code_link:hover {
    text-decoration: underline !important;
}

.ratecourier-cost .sub_info.break-down {
    display: flex;
    flex-direction: column;
}

.ratecourier-cost .sub_info.break-down .sub_form_sections {
    display: flex;
}

.ratecourier-cost .sub_info.break-down .sub_form_sections .add_remove_btns {
    position: static;
    top: auto;
    left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ratecourier-cost .sub_info.break-down .sub_form_sections .add_remove_btns .add_bt {
    position: static;
    top: auto;
    left: auto;
}

.packages-heading {
    margin-bottom: 60px !important;
}

.package-border,
.line-item-border {
    border: 1px solid #80808044;
    margin-bottom: 7px;
}

._sur_flex_boxes.small.action {
    width: 150px !important;
    align-self: flex-end;
    padding-bottom: 2px;
}

.package-label,
.line-item-label {
    color: white;
    background: #004A7C;
    padding: 3px;
    font-size: 13px;
}

._sur_container .line-items {
    padding: 0px 20px 20px 20px;
}

._sur_flex_container .line-item-details {
    margin-top: 0px !important;
}
</style>
